<template>
  <div>
    <Dialog
      v-bind="$attrs"
      title="Move to another collection"
      :hide-heading="false"
      icon="mdi-image-plus"
      :width="$vuetify.breakpoint.xsOnly ? $vuetify.breakpoint.width : showResources ? 1000 : 1000"
      :fullscreen="!$vuetify.breakpoint.smAndUp"
      :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
      v-on="$listeners"
    >
      <template #subTitle>
        <span class="subtitle-2 font-weight-regular ">{{ selectedCards.length }} resources selected</span>
        <AdsButton
          tertiary
          dense
          small
          :button-text="showResources? 'Hide': 'Show'"
          @click="showResources = !showResources"
        />
      </template>
      <template #content>
        <v-container class="mt-8">
          <v-card-text v-if="showResources">
            <v-row>
              <v-col
                v-for="(item, index) in selectedCards"
                :key="index"
                lg="4"
                sm="12"
              >
                <ResourceCard
                  dense
                  :size="360"
                  :height="260"
                  :item="item"
                  :isSelected="true"
                  :enableNavigation="false"
                  :rounded="$vuetify.breakpoint.width >= 620"
                  v-on="$listeners"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider />
          <v-card-text class="scrollSection">
            <EditableCollections
              v-model="selectedCollections"
              :hub-collection="hubCollection"
              :exclude="collection.record.id"
              @onCollectionSelect="onCollectionSelectHandler"
            />
          </v-card-text>

          <v-card-actions :class="{'d-flex justify-space-between px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
            <AdsButton
              tertiary
              button-text="Cancel"
              @click="$downwardBroadcast('close:dialog');$emit('cancel')"
              @keyup.enter="$downwardBroadcast('close:dialog');$emit('cancel')"
            />
            <!-- if no selected cards, disable the Save button -->
            <AdsButton
              primary
              button-text="Save changes"
              :disabled="selectedCards?.length < 1 || selectedCollections.length < 1"
              @click="moveResourcesTo()"
              @keyup.enter="moveResourcesTo()"
            />
          </v-card-actions>
        </v-container>
      </template>
    </Dialog>
  </div>
</template>
<script>
import { AdsButton } from '@nswdoe/doe-ui-core';
import Dialog from '@/components/Dialogs/Dialog.vue';
import {mapState} from 'vuex';
import ResourceCard from '@/components/Resource/ResourceCard'
import EditableCollections from '@/components/Collection/EditableCollections'
import store from '@/store'

export default {
  name: 'MoveToCollectionDialog',
  components: {
    EditableCollections,
    AdsButton,
    Dialog,
    ResourceCard
  },
  props: {
    items: { // all resources in the collection
      type: Array,
      default: () => []
    },
    selectedCards: { // all selected resources in the collection
      type: Array,
      default: () => []
    },
    hubCollection: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      showDialog: false,
      showResources: false,
      selectedCollections: []
    };
  },
  computed: {
    ...mapState({
      collection: state => state.myCollections.collectionDetails || '',
    }),
  },
  methods: {
    // remove selected resources from collection and update
    async moveResourcesTo() {
      this.$downwardBroadcast('close:dialog');
      try {

        const resources = this.selectedCards.map(resource => {
          return {
            ItemID: resource.resourceId.id,
            Source: resource.resourceId.source
          }
        })

        // Step 1: Remove from the existing collection
        await this.$store.dispatch('myCollections/removeResources', {
          collection: this.collection,
          subResources: this.selectedCards
        });

        // To validate for duplicates
        const invalidResources = []
        const validUpdates = []

        // Step 2: Copy resources to the new collections with a 1s delay
        const apiCallPromisses = this.selectedCollections.map(async (collection, index) => {

          // list of resources already exist in the target collection
          const collectionSubResources = collection.subResources.flatMap(subs => subs.resourceId?.id)

          const [invalid, valid] = resources.reduce(([pass, fail], elem) => {
            // push to the invalid list if it is already exist in the collection
            return collectionSubResources.includes(elem.ItemID) ? [[...pass, elem.ItemID], fail] : [pass, [...fail, elem]];
          }, [[], []]);

          invalid?.length > 0 && invalidResources.push(`<li>The resource(s) ${invalid.join(', ')} already exists in the Collection ${collection.Name}</li>`)

          return valid.length > 0 && new Promise(() => setTimeout(async () => {
            validUpdates.push(collection) // saving updated collection details
            await this.$store.dispatch('myCollections/addResources', {
              collection: collection,
              subResources: resources
            })
          }, 1000 * index));
        })

        await Promise.all([apiCallPromisses]);

        // Step 3: refresh this collection
        await store.dispatch('myCollections/fetchCollection', {
          id: this.collection.record.id,
          hubCollection: this.hubCollection,
          application: this.collection?.audit?.application,
          source: this.collection?.record.collection,
          rce: true
        });

        setTimeout(() => {
          // Step 4: fetch all my editable collections so all dialogs will be updated
          this.$store.dispatch('myCollections/fetchMyEditableCollections', { hubCollection: this.hubCollection }), 1000 * this.selectedCollections.length
          // Step 5: Clear selections in parent
          this.$emit('onComplete');

          const alert = validUpdates.length < 1 ? {
            type: 'error',
            text: `The following error(s) occured while trying to move resource[s]: <ul> ${invalidResources.join('')}</ul>`
          }: (invalidResources.length > 0) ?  {
            type: 'warning',
            text: `The following error(s) occured while trying to move resource[s]: <ul> ${invalidResources.join('')}</ul>`
          } : {
            type: 'success',
            text: 'Your collection is successfully updated.'
          }
          // Step 5: Show alert
          this.$store.commit('SET_ALERT', alert, {root: true});
        });
      } catch (err) {
        console.error(err);
        const alert = {
          type: 'error',
          text: 'There was an error saving your resource.'
        }
        this.$store.commit('SET_ALERT', alert, {root: true});
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
        this.$store.commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => this.$store.commit('SET_DISPLAY_ALERT', false, {root: true}), 20000);
      }
      this.selectedCollections = []
    },
    onCollectionSelectHandler(val) {
      /**
       * val: {
       *   select: type Boolean, // selected or not
       *   item: type Object<ResourceItem>
       * }
       * **/
      if(val.select) {
        // add object to array
        this.selectedCollections.push(val.item)
      } else {
        //delete from list
        this.selectedCollections = this.selectedCollections.filter(card => card.ItemID !== val.item.ItemID)
      }
      console.log(this.selectedCollections.length + ' selected ' + val.select)
    }
  }
};
</script>
<style lang="scss" scoped>
@import "src/scss/variables";
@import "../../../scss/mixins";
.scrollSection{
  @include customScrollbar(phone, $ads-light-40, $ads-white, $ads-light-40);
}

</style>
