<template>
  <v-card class="mb-5 ">
    <div class="test-card-row d-flex flex-no-wrap justify-start">
      <div class="image__container">
        <Chip
          v-if="isCollection"
          chipClass="collection-chip px-4 mr-2 mb-2 mt-1"
          small
          style="font-weight: bold"
          :textColor="ADS_Colors.GREY_01"
          text="COLLECTION"
          :color="ADS_Colors.GREY_01_DARK"
        />
        <template v-if="isCollection">
          <v-img
            v-if="item.document.ThumbnailImage"
            :src="item.document.ThumbnailImage"
            :lazy-src="item.document.ThumbnailImage"
            height="100%"
            position="left center"
          />
          <ImageCollage
            v-else
            :images="collectionImages"
          />
        </template>
        <v-img
          v-else
          height="100%"
          min-height="120px"
          position="left center"
          :src="imageUrl"
          :lazy-src="resourceDefaultImage"
          :contain="false"
          :eager="true"
          alt=""
          class="rounded-2"
        />
      </div>
      <div style="width: 75%">
        <v-card-title class="pb-0 pt-2">
          <span
            v-if="!isCollection"
            style="font-size: 14px; font-weight: bold; display: block; color: #1D428A;"
            class="fontColorResourceType"
            :aria-label="'resource type '+ resourceType"
          >
            {{ resourceType }}
          </span>
        </v-card-title>
        <v-card-title class="pt-0 pb-2">
          <router-link
            :id="itemId"
            :to="(isCollection? '/collection/': '/detail/') + itemId"
            tabindex="0"
            class="primary-control"
          >
            <h2 class="card-title">
              {{ resource.Name }}
            </h2>
          </router-link>
        </v-card-title>
        <v-card-subtitle class="pr-6 pt-2 pb-1">
          <span>Updated: {{ moment(updatedDate).format('DD MMM YYYY') }} </span>
        </v-card-subtitle>
        <v-card-text
          v-if="isTLR"
          class="pt-0 pb-4"
        >
          <span
            style="font-weight: bold;"
            class="mr-2"
          >Years:</span>
          <span>{{ resource.Year.join(', ') }}</span>
          <span
            style="font-weight: bold;"
            class="ml-9 mr-2"
          >Learning Area:</span>
          <span>{{ resource.KLA.join(', ') }}</span>
        </v-card-text>
        <v-card-text
          v-if="isSIR"
          class="pt-0 pb-4"
        >
          <span
            class="font-weight-bold mr-2"
          >Focus Area:</span>
          <span>{{ focusAreas }}</span>
        </v-card-text>
        <v-card-text
          class="pt-0 pb-0 card-desc">
          <span v-html="resource.Description ? resource.Description : ''" />
        </v-card-text>
      </div>
      <div style="width: 25%">
        <template v-if="isCollection">
          <AdsButton primary class="my-3 mr-" style="width: 96%">Open</AdsButton>
          <div class="my-3 d-flex justify-space-between">
            {{ item.subResources.length }} resources
            <Chip
              v-if="isFollowing"
              chipClass="status-chip font-weight-bold px-4 mr-3 my-1"
              small
              :textColor="ADS_Colors.Navy"
              text="FOLLOWING"
              :color="ADS_Colors.Blue_3"
            />
          </div>

        </template>
        <template
          v-else
        >
          <div class="my-3 mr-3">
            <GoToResource
              :id="resource.ItemID"
              :item-url="resource.ResourceURL"
            />
          </div>
          <div class="mr-3">
            <Share
              :item="item"
              align-right
            />
          </div>
        </template>
      </div>
    </div>
  </v-card>
</template>

<script>
import moment from 'moment';
import * as prettyBytes from 'pretty-bytes';
import Share from '../../components/Share';
import GoToResource from '../../components/GoToResource';
import resourceDefaultImage from '@/assets/default_myresources_image.png';
import {mapGetters, mapState} from 'vuex'
import ImageCollage from '@/components/ImageCollage';
import {AdsButton, ADS_Colors, Chip} from '@nswdoe/doe-ui-core'

export default {
  name: 'ResourceCardList',
  components: {
    AdsButton,
    Chip,
    ImageCollage,
    Share,
    GoToResource
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      moment,
      prettyBytes,
      resourceDefaultImage,
      ADS_Colors
    }
  },
  computed: {
    ...mapState({
      resourceDetails: state => state.resourceDetails
    }),
    ...mapGetters({
      allHubResourceTypes: 'metadata/allHubResourceTypes',
      userProfile: 'users/userProfile'
    }),
    isCollection() {
      return this.item.record.isResourceCollection;
    },
    isFollowing() {
      return this.item.userAccess?.followers?.includes(this.userProfile.userId)
    },
    collectionImages(){
      return this.item.subResources?.map( item => item.image)
    },
    isSIR() {
      return this.item.ResourceCategory?.includes('sir');
    },
    isTLR() {
      return this.item.ResourceCategory?.includes('tlr');
    },
    focusAreas() {
      return this.item.FocusArea?.map(e => e.focusArea).join(', ');
    },
    resourceType() {
      // Lookup in metadata for the resourceType label
      return this.allHubResourceTypes.length > 0 && this.allHubResourceTypes?.find(type => {
        return type.id === this.resource.ResourceType
      })?.name || 'Resource';
    },
    resource() {
      if (this.item.document) {
        if (this.isDraft && !this.isEditor && !this.isOwner) {
          const reversedArchive = this.item.archive.slice().reverse();
          const beforeDraft = reversedArchive.find(resource => !resource.document.draft);
          return beforeDraft.document;
        }
        return this.item.document;
      } else {
        return this.item;
      }
    },
    updatedDate() {
      return this.item.record.modified ? this.item.record.modified : this.item.record.created;
    },
    imageUrl() {
      if (this.resource.ThumbnailImage) {
        return this.resource.ThumbnailImage;
      } else {
        return resourceDefaultImage;
      }
    },
    itemId() {
      return this.item?.document?.ItemID;
    },
  }
}
</script>

<style lang="scss" scoped>
.image__container {
  max-width: 200px;
  max-height: 130px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .v-image {
    border-radius: 4px;
  }
  .collection-chip {
    padding: 0 15px;
    border-radius: 4px;
    position: absolute;
    background-color: $ads-dark-70;
    font-weight: bold;
    color: $ads-white;
    top: 8px;
    left: 10px;
    z-index: 1;
  }
}

.card-desc {
  font-size: 12px;
  line-height: 14px;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.primary-control {
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  .card-title {
    font-size: 18px;
    font-weight: bold;
    word-break: keep-all;
    line-height: 20px;
    min-height: 35px;
    color: #041E42;
  }
}
</style>
