<template>
  <ul class="keywords__wrapper">
    <li
      v-for="keyword in itemDetail.Keywords"
      :key="keyword"
    >
      <v-chip
        tabindex="0"
        :color="ADS_Colors.Navy"
        outlined
        :text-color="ADS_Colors.Navy"
        class="chip"
        role="button"
        title="Click to search"
        @click="search(keyword)"
        @keyup.enter="search(keyword)"
      >
        {{ keyword }}
      </v-chip>
    </li>
  </ul>
</template>

<script>
import {ADS_Colors} from '@nswdoe/doe-ui-core';
import {mapState} from 'vuex';

export default {
  name: 'Keywords',
  data() {
    return {
      ADS_Colors
    };
  },
  computed: {
    ...mapState({
      itemDetail: state => state.itemDetail
    })
  },
  methods: {
    search(keyword) {
      keyword = `"${keyword}"`;
      if (!this.$store.state.searchMode) {
        this.$store.commit('SET_SEARCH_MODE', true);
      }
      this.$store.commit('CLEAR_SEARCH');
      this.$store.commit('SET_KEYWORDS', keyword);
      this.$store.dispatch('fetchResourcesWithClient');
      this.$router.push({name: 'HubResourcesPage'});
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.keywords__wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px;

  li {
    list-style: none;
  }
}

.chip {
  margin: 8px;

  &:focus {
    color: $ads-white !important;
  }
}

::v-deep.v-chip {
  &.v-chip--outlined {
    &.v-chip {
      &.v-chip {
        &:focus {
          background-color: $ads-navy !important;
        }
      }
    }
  }
}
</style>
