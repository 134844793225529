<template>
  <div
    class="image--collage fill-height"
    :class="{'rounded-lg': rounded}"
  >
    <div
      v-if="!displayImages[0]"
      class="default-image fill-height d-flex align-center justify-center"
    >
      <v-icon
        :size="rounded?30:60"
        class="material-icons-outlined"
        color="primary"
      >
        bookmarks
      </v-icon>
    </div>
    <template v-else>
      <!-- template for 4 or more images -->
      <div
        v-if="displayImages[3]"
        class="fill-height"
      >
        <!-- top image goes full width -->
        <div class="vertical-spacer fill-two-third">
          <v-img
            :src="displayImages[0]"
            :lazy-src="defaultImage"
            height="100%"
            position="left bottom"
            class="grey lighten-2"
          />
        </div>
        <!-- bottom three up -->
        <div class="d-flex fill-one-third">
          <v-img
            :src="displayImages[1]"
            :lazy-src="defaultImage"
            height="100%"
            position="left"
            class="third horizontal-spacer"
          />
          <v-img
            :src="displayImages[2]"
            :lazy-src="defaultImage"
            height="100%"
            position="left"
            class="third horizontal-spacer"
          />
          <div
            class="third last"
          >
            <v-overlay
              v-if="moreImages > 0"
              absolute
              color="#000000"
            >
              +{{ images.length - displayImages.length }}
            </v-overlay>
            <v-img
              :src="displayImages[3]"
              :lazy-src="defaultImage"
              height="100%"
              position="left"
            />
          </div>
        </div>
      </div>

      <!-- template for 3 or less images  -->
      <div
        v-else
        class="d-flex fill-height"
      >
        <!-- if more than 1 image display first in first column -->
        <div
          class="horizontal-spacer"
          :class="displayImages[1] ? 'half': 'full'"
        >
          <v-img
            v-if="displayImages[0]"
            :src="displayImages[0]"
            :lazy-src="defaultImage"
            height="100%"
            position="left"
            class="grey lighten-2"
          />
        </div>
        <div class="half fill-height">
          <div
            v-if="displayImages[1]"
            :class="{'half-height vertical-spacer': displayImages[2], 'fill-height': !displayImages[2]}"
          >
            <v-img
              :src="displayImages[1]"
              :lazy-src="defaultImage"
              height="100%"
              :position="!displayImages[2] ? 'right':''"
              class="horizontal-spacer"
            />
          </div>
          <div
            v-if="displayImages[2]"
            class="half-height last"
          >
            <v-overlay
              v-if="moreImages > 0"
              absolute
              color="#000000"
            >
              +{{ images.length - displayImages.length }}
            </v-overlay>
            <v-img
              :src="displayImages[2]"
              :lazy-src="defaultImage"
              height="100%"
              position=""
            />
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import defaultImage from '@/assets/default_myresources_image.png'

export default {
  name: 'ImageCollage',
  props: {
    displayNo: {
      type: Number,
      default: 3
    },
    images: {
      type: Array,
      default: () => []
    },
    alt: {
      type: String,
      default: 'Image collection'
    },
    rounded: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultImage
    }
  },
  computed: {
    displayImages() {
      return [...this.images].splice(0, this.displayNo)
    },
    moreImages() {
      return this.images.length - this.displayImages.length
    }
  }
}
</script>
<style lang="scss">

.image--collage {
  width: 100%;
  overflow: hidden;

  .default-image {
    background: #C8DCF0;
    .v-icon {
      margin: auto 0;
    }
  }
  &.rounded {
    border-radius: 5px;
  }
  .v-image:not(:last-child) {
    border-right: none;
  }
  .horizontal-spacer {
    margin-right: 2px;
  }
  .vertical-spacer {
    margin-bottom: 2px;
  }
  .half {
    width: 50%;
  }
  .last {
    position: relative;
    font-weight: 900;
  }
  .full {
    width: 100%;
  }
  .half-height {
    height: 50%;
  }
  .fill-two-third {
    height: 70%;
  }
  .fill-one-third {
    height: 30%;
  }
  .third {
    width: 34%;

    &.last {
      position: relative;
      font-weight: 900;
    }
  }
}
.number-badge {
  width: 118px;
  height: 70px;
  background: $ads-navy;
  padding: 20px;
  opacity: .6;
  z-index: 9999;
  font-weight: bold;
  color: $ads-white;
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.v-image {

  //object-fit: cover;
  //width: 50%;

  &:not(:last-child){
    border-right: 2px solid $ads-white;
  }

  &.v-responsive {
    flex: none;
  }
}
</style>
