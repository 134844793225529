<template>
  <BasePage
    :banner="banner"
    :showNotifications="true"
    :card="{maxSize: 424, minSize: $vuetify.breakpoint.mdAndDown ? 288 : 310}"
    :applyMaxWidth="!showGridView"
    @get:cardSize="getCardSize"
  >

    <!--    1 - Header section-->
    <v-btn
      v-if="$store.state.preferencesMode"
      depressed
      text
      class="pl-0 mb-3"
      color="primary"
      @click="$store.commit('SET_PREFERENCES_MODE', false)"
      @keyup.enter="$store.commit('SET_PREFERENCES_MODE', false)"
    >
      <v-icon class="pr-2">
        mdi-arrow-left
      </v-icon>
      Back
    </v-btn>

    <div class="search__wrapper">
      <div class="search__container">
        <AdsTextField
          v-model="searchString"
          solo
          prepend-inner-icon="search"
          clearable
          flat
          class="search-bar"
          :label="placeholder"
          :rules="[ validCharacters ]"
          :hide-details="!searchFieldError"
          @update:error="handleError"
        />
        <SavedSearchesMenu />
      </div>

      <!--      Filters   -->
      <v-row
        dense
        align="center"
        justify="start"
        class="chip-filters__wrapper mt-4"
      >
        <!--    **Hub only Filters**   -->

        <!--  1. Stage  -->
        <!--  Show if itStage  -->
        <v-col
          v-if="isTLR || showAllFilterCategories"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="years"
            v-model="checkboxYears"
            name="Stage"
            heading="Stage"
            multiLevel
            :items="$store.getters['metadata/stagesAndYears']"
          />
        </v-col>

        <!-- 2. Learning Area  -->
        <v-col
          v-if="isTLR || showAllFilterCategories"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="Learning Area"
            v-model="checkboxKLAs"
            name="Learning Area"
            heading="Learning Area"
            :items="$store.getters['metadata/klas']"
          />
        </v-col>

        <!-- 3. Hub-Type (the new Resource Category)  -->
        <v-col
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="Type"
            v-model="checkboxHubType"
            heading="Resource type"
            name="Type"
            multiLevel
            :items="hubTypes"
          />
        </v-col>

        <!-- 4. Library (r4.4)  -->
        <v-col
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="Library"
            v-model="checkboxUrhLibrary"
            heading="Library"
            name="Library"
            multiLevel
            :items="$store.getters['metadata/urhlibrary']"
          />
        </v-col>

        <!-- 2. Hub-Format (Type in R3)  -->
        <!--    <v-col
          v-if="!isMyResources"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="Format"
            heading="Resource format"
            name="Format"
            :items="$store.getters['metadata/hubFormat']"
            v-model="filters.checkboxResourceType"
          />
        </v-col>-->

        <!-- 3. Phase-->
        <v-col
          v-if="checkboxPhase.length > 0"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="Phase"
            v-model="checkboxPhase"
            :heading="checkboxResourceCategory?.length === 1 ? 'Phase': 'Teaching and learning and School improvement cycles'"
            name="Phase"
            multiLevel
            :items="phases"
          />
        </v-col>

        <!-- 4. Source-->
        <v-col
          v-if="checkboxSource.length > 0"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="Source"
            v-model="checkboxSource"
            name="Owner"
            heading="Owner"
            :alignRight="$vuetify.breakpoint.smOnly"
            :items="sources"
          />
        </v-col>

        <!--    5. L&N Topics-->
        <v-col
          v-if="$store.state.showRandNFilter && checkboxRandN.length > 0"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="L&N Topics"
            v-model="checkboxRandN"
            name="L&N Topics"
            heading="L&N Topics"
            multiLevel
            :selectableParent="false"
            :alignRight="$vuetify.breakpoint.smOnly"
            :minWidth="290"
            :items="$store.getters['metadata/filterRAndNTopics']"
          />
        </v-col>

        <!-- 6. SEF Alignment  -->
        <v-col
          v-if="(isSIR || showAllFilterCategories) && checkboxSefAlignment.length > 0"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="SEF Alignment"
            v-model="checkboxSefAlignment"
            heading="SEF Alignment"
            name="SEF Alignment"
            multiLevel
            :items="$store.getters['metadata/sefAlignment']"
          />
        </v-col>

        <!-- 7. Focus Area  -->
        <v-col
          v-if="(isSIR || showAllFilterCategories) && checkboxFocusArea.length > 0"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="Focus Area"
            v-model="checkboxFocusArea"
            heading="Focus Area"
            name="Focus Area"
            multiLevel
            :items="$store.getters['metadata/focusArea']"
          />
        </v-col>
        <v-col
          v-if="checkboxResourceCategory.length > 0"
          sm="auto"
          md="auto"
          lg="auto"
        >
          <ChipFilter
            id="Category"
            v-model="checkboxResourceCategory"
            heading="Category"
            name="Category"
            multiLevel
            :items="$store.getters['metadata/resourcecategories']"
          />
        </v-col>
        <!--        <v-col-->
        <!--          cols="auto"-->
        <!--          align-self="center"-->
        <!--        >-->
        <!--          <AdsButton-->
        <!--            class="clear-all"-->
        <!--            tertiary-->
        <!--            button-text="Clear all"-->
        <!--            @click="handleClearAllFilters"-->
        <!--            aria-label="clear all"-->
        <!--          />-->
        <!--        </v-col>-->
      </v-row>
      <v-row dense>
        <v-col>
          <FilterMenu
            @clear="handleClearAllFilters"
            @update="updateSearchModeAndFetch"
          />
        </v-col>
      </v-row>
    </div>

    <!--   2 - Show Searching page-->
    <template v-if="isSearching">
      <SearchResultsHeader
        v-if="!$store.state.isLoading"
        :count="$store.state.searchResultsCount"
        :sorting="$store.state.sort"
        :showView="showGridView ? 0 : 1"
        @toggle:view="toggleView"
      />
      <SearchResultsContent
        :loading="$store.state.isLoading"
        :resources="$store.state.resources.items"
        :grid-view="showGridView"
        :card-size="cardSize"
      />
    </template>
    <!--   2 - Show Home page-->
    <template v-if="!isSearching">
      <HomePage />
    </template>
  </BasePage>
</template>

<script>
import BasePage from '@/components/BasePage';
import SearchResultsHeader from '@/views/HubResourcesPage/SearchResultsHeader';
import SearchResultsContent from '@/views/HubResourcesPage/SearchResultsContent';
import HomePage from '@/views/HubResourcesPage/HomePage';
import {mapGetters} from 'vuex';
import {AdsTextField, ADS_Colors, ChipFilter} from '@nswdoe/doe-ui-core';
import {QUERIES, SORT_OPTIONS, HUB_FILTERS_STRUCTURE, HUB_FILTERS_SELECTION_STRUCTURE} from '@/constants';
import SavedSearchesMenu from '@/views/HubResourcesPage/SavedSearchesMenu';
import isEqual from 'lodash.isequal';
import _debounce from 'lodash/debounce'
import FilterMenu from '@/components/Menus/FilterMenu';

export default {
  name: 'HubResourcesPage',
  components: {
    FilterMenu,
    SearchResultsContent,
    SearchResultsHeader,
    BasePage,
    HomePage,
    AdsTextField,
    SavedSearchesMenu,
    ChipFilter,
  },
  data() {
    return {
      banner: {
        heading: 'Hub Resources',
        subtitle: 'Browse or search our library of school resources',
        icon: 'vertical_split',
        iconSize: '3.5rem'
      },
      showGridView: true,
      cardSize: 350,
      selectItems: [
        {
          text: 'All',
          value: ''
        },
        {
          text: 'School improvement',
          value: 'sir',
          icon: {
            name: 'build',
            background: ADS_Colors.Warning_Orange,
            color: 'white',
            size: '20'
          }
        },
        {
          text: 'Teaching and learning',
          value: 'tlr',
          icon: {
            name: 'school',
            background: ADS_Colors.Blue_1,
            color: 'white',
            size: '20'
          }
        }
      ],
      queriesUpdatedInternally: false,
      searchFieldError: false,
      queriesOptions: QUERIES,
      sortOptions: SORT_OPTIONS,
      validCharacters: v => !v || /^[a-zA-Z0-9 '.\-+=&_/"]*$/.test(v) || 'Search parameters cannot include the special characters used',
      // filters selection
      checkboxYears: [],
      checkboxKLAs: [],
      checkboxResourceType: [],
      // new in R4:
      checkboxFormat: [],
      checkboxHubType: [],
      checkboxUrhLibrary: []
    };
  },
  created() {
    // to avoid multiple api calls on each state update
    this.debouncedFetchResourcesWithClient = _debounce(this.fetchResourcesWithClient, 500)
  },
  computed: {
    ...mapGetters({
      userProfile: 'users/userProfile',
      allHubTypes: 'metadata/hubTypes',
      allPhases: 'metadata/phases',
      allSources: 'metadata/sourcesForHub'
    }),
    isSearching() { // !isSearchPage = render <HomePage />
      if (this.$store.state.searchMode ||
        this.$store.state.preferencesMode ||
        this.searchString && this.searchString.length >= 3 ||
        !this.$store.getters.isFiltersEmpty) {
        return true;
      } else {
        return false;
      }
    },
    searchString: {
      get() {
        return this.$store.state.keywords;
      },
      set(value) {
        // when click the X button in the search bar, value becomes null
        if (value === null || value === '') {
          this.$store.commit('SET_KEYWORDS', '');
          this.updateRouterQueriesForURL();
          // this.$store.commit('SET_SEARCH_MODE', false);
          if (this.isSearching) {
            this.debouncedFetchResourcesWithClient()
          }
        } else if (value.length >= 3) {
          this.$store.commit('SET_KEYWORDS', value);
          this.updateRouterQueriesForURL();
          this.$store.commit('SET_SEARCH_MODE', true);
          this.debouncedFetchResourcesWithClient()
        }
      }
    },
    checkboxResourceCategory: {
      get() {
        return this.$store.state.filtersSelection.checkboxResourceCategory;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxResourceCategory) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxResourceCategory: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            'resource-category': newVal.join()
          });

          this.updateSearchModeAndFetch()
        }
      }
    },
    checkboxSource: {
      get() {
        return this.$store.state.filtersSelection.checkboxSource;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxSource) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxSource: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            'resource-sources': newVal.join()
          });

          // additional logic for checkboxSource
          if (newVal && (newVal.includes('readingandnumeracy') || newVal.includes('literacyandnumeracypackages'))) {
            this.$store.commit('SET_SHOW_RANDN_FILTER', true);
          } else {
            this.$store.commit('SET_SHOW_RANDN_FILTER', false);
            this.checkboxRandN = [];
          }

          this.updateSearchModeAndFetch()
        }
      }
    },
    checkboxPhase: {
      get() {
        return this.$store.state.filtersSelection.checkboxPhase;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxPhase) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxPhase: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            phase: newVal.join()
          });

          this.updateSearchModeAndFetch()
        }
      }
    },
    checkboxSefAlignment: {
      get() {
        return this.$store.state.filtersSelection.checkboxSefAlignment;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxSefAlignment) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxSefAlignment: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            sefalignment: newVal.join()
          });

          this.updateSearchModeAndFetch()
        }
      }
    },
    checkboxFocusArea: {
      get() {
        return this.$store.state.filtersSelection.checkboxFocusArea;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxFocusArea) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxFocusArea: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            focusarea: newVal.join()
          });
          this.updateSearchModeAndFetch()
        }
      }
    },
    checkboxRandN: {
      get() {
        return this.$store.state.filtersSelection.checkboxRandN;
      },
      async set(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxRandN) && newVal === oldVal) {
          return;
        } else {
          await this.$store.commit('SET_FILTERS_SELECTION', {
            ...this.$store.state.filtersSelection,
            checkboxRandN: newVal
          });

          await this.$store.commit('SET_FILTERS', {
            ...this.$store.state.filters,
            'resource-randntopics': newVal.join()
          });

          this.updateSearchModeAndFetch()
        }
      }
    },
    placeholder() {
      const placeholder = 'Search on a keyword, "phrase (inc. quotation marks)", topic, NSW Syllabus or Australian Curriculum codes/descriptions';
      const placeholderMobile = 'Search here for Hub resources';
      return this.$vuetify.breakpoint.xsOnly ? placeholderMobile : placeholder;
    },
    hubTypes() {
      //show all when no category is selected
      if (this.showAllFilterCategories) {
        return this.allHubTypes.filter(p => p.resourcecatid.match(/sir|tlr/));
      }

      // highly depend on the order of the type.resourcecatid list when BE required to send multiple categories
      // type.resourcecatid is the first item of the resourcecatid list adjusted in the getter
      // otherwise need to loop the resourceCategory items if the order is unpredictable
      return this.allHubTypes.filter(type => {
        return this.checkboxResourceCategory?.join(',').includes(type.resourcecatid)
      })
    },
    phases() {
      //show all when no category is selected
      if (this.showAllFilterCategories) {
        return this.allPhases.filter(p => p.resourcecatid.join(',').match(/sir|tlr/)); // only allow sir and tlr for filtering
      }

      // highly depend on the order of the source.resourcecatid list when BE required to send multiple categories
      // otherwise need to loop the resourceCategory items if the order is unpredictable
      return this.allPhases.filter(phase => {
        return this.checkboxResourceCategory?.join(',').includes(phase.resourcecatid.join(','))
      })
    },
    sources() {
      //show all when no category is selected
      if (this.showAllFilterCategories) {
        return this.allSources;
      }

      // highly depend on the order of the source.resourcecatid list when BE required to send multiple categories
      // otherwise need to loop the resourceCategory items if the order is unpredictable
      return this.allSources.filter(source => {
        return this.checkboxResourceCategory?.join(',').includes(source.resourcecatid.join(','))
      })
    },
    // is a Teaching and Learning Resource
    isTLR() {
      return this.checkboxResourceCategory?.includes('tlr');
    },
    showAllFilterCategories() {
      return this.checkboxResourceCategory?.length === 0 || this.checkboxResourceCategory.length === this.$store.getters['metadata/resourcecategories'].length;
    },
    // is a School Improvement Resource
    isSIR() {
      return this.checkboxResourceCategory?.includes('sir')
    }
  },
  watch: {
    // async '$route.query'(newVal, oldVal) {
    //   if (!isEqual(newVal, oldVal) && this.$route.name === 'HubResourcesPage') { // make sure the logic below only applies on this page
    //     if (!this.queriesUpdatedInternally) {
    //       await this.handleQueryParamsFromURL();
    //       await this.debouncedFetchResourcesWithClient()
    //     }
    //     this.queriesUpdatedInternally = false
    //   }
    // },
    userProfile: {
      immediate: true,
      deep: true,
      async handler(userProfile) {
        let query = {...this.$route.query};
        await this.$store.commit('SET_SEARCH_MODE', this.searchString !== '' || Object.keys(query).length > 0);
      }
    },
    '$store.state.sort'() {
      if (this.$store.state.searchMode) {
        this.updateRouterQueriesForURL();
      }
    },
    /*    isSearching(val) {
          if (val) {
            // this.$forceUpdate();
          }
        },*/
    '$store.state.filtersSelection': {
      deep: true,
      handler(newVal, oldVal) {
        if (!isEqual(newVal, oldVal)) {
          this.updateRouterQueriesForURL();
          // when state.filtersSelection changes, update local filtersSelection arrays so the changes are reflected on UI
          this.checkboxResourceCategory = newVal.checkboxResourceCategory;
          this.checkboxYears = newVal.checkboxYears;
          this.checkboxKLAs = newVal.checkboxKLAs;
          this.checkboxResourceType = newVal.checkboxResourceType;
          this.checkboxSource = newVal.checkboxSource;
          this.checkboxRandN = newVal.checkboxRandN;
          this.checkboxFormat = newVal.checkboxFormat;
          this.checkboxHubType = newVal.checkboxHubType;
          this.checkboxPhase = newVal.checkboxPhase;
          this.checkboxSefAlignment = newVal.checkboxSefAlignment;
          this.checkboxFocusArea = newVal.checkboxFocusArea;
          this.checkboxUrhLibrary = newVal.checkboxUrhLibrary;
        }
      }
    },
    // filters selection
    checkboxUrhLibrary: {
      deep: true,
      async handler(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxUrhLibrary) && newVal === oldVal) {
          return;
        } else {
          const filtersSelection = {...this.$store.state.filtersSelection};
          filtersSelection.checkboxUrhLibrary = newVal;
          await this.$store.commit('SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.filters};
          newFilters.urhlibrary = newVal?.join();
          await this.$store.commit('SET_FILTERS', newFilters);

          this.updateSearchModeAndFetch()
        }
      }
    },
    checkboxYears: {
      deep: true,
      async handler(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxYears) && newVal === oldVal) {
          return;
        } else {
          const filtersSelection = {...this.$store.state.filtersSelection};
          filtersSelection.checkboxYears = newVal;
          await this.$store.commit('SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.filters};
          newFilters['resource-years'] = newVal.join();
          await this.$store.commit('SET_FILTERS', newFilters);

          this.updateSearchModeAndFetch()
        }
      }
    },
    checkboxKLAs: {
      deep: true,
      async handler(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxKLAs) && newVal === oldVal) {
          return;
        } else {
          const filtersSelection = {...this.$store.state.filtersSelection};
          filtersSelection.checkboxKLAs = newVal;
          await this.$store.commit('SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.filters};
          newFilters['resource-klas'] = newVal.join();
          await this.$store.commit('SET_FILTERS', newFilters);

          this.updateSearchModeAndFetch()
        }
      }
    },
    checkboxResourceType: {
      deep: true,
      async handler(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxResourceType) && newVal === oldVal) {
          return;
        } else {
          const filtersSelection = {...this.$store.state.filtersSelection};
          filtersSelection.checkboxResourceType = newVal;
          await this.$store.commit('SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.filters};
          newFilters['resource-types'] = newVal.join();
          await this.$store.commit('SET_FILTERS', newFilters);

          this.updateSearchModeAndFetch()

        }
      }
    },
    checkboxFormat: {
      deep: true,
      async handler(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxFormat) && newVal === oldVal) {
          return;
        } else {
          const filtersSelection = {...this.$store.state.filtersSelection};
          filtersSelection.checkboxFormat = newVal;
          await this.$store.commit('SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.filters};
          newFilters.formats = newVal.join();
          await this.$store.commit('SET_FILTERS', newFilters);

          this.updateSearchModeAndFetch()
        }
      }
    },
    checkboxHubType: {
      deep: true,
      async handler(newVal, oldVal) {
        if (isEqual(newVal, this.$store.state.filtersSelection.checkboxHubType) && newVal === oldVal) {
          return;
        } else {
          const filtersSelection = {...this.$store.state.filtersSelection};
          filtersSelection.checkboxHubType = newVal;
          await this.$store.commit('SET_FILTERS_SELECTION', filtersSelection);

          const newFilters = {...this.$store.state.filters};
          newFilters.resourcetype = newVal.join();
          await this.$store.commit('SET_FILTERS', newFilters);

          this.updateSearchModeAndFetch()
        }
      }
    },
  },
  async mounted() {
    /*
    When this route component is mounted, if there are query params (can get via $route.query) in URL, e.g. "/home?search=school&years=YEAR_K&source=readingandnumeracy&sort=relevance",
    we need to set searchMode to true, and perform a search based on the query params, and restore keywords, filters, sort on the UI.
    */
    if (Object.keys(this.$route.query).length > 0) { // if $route.query object has fields (URL contains search params)
      this.handleQueryParamsFromURL();
      this.$store.commit('SET_SEARCH_MODE', true);
      this.$store.commit('SET_IS_DIRECT_ACCESS', false);
    } else if (Object.keys(this.$store.getters.filters).some(filter => this.$store.getters.filters[filter].length > 0)) {
      // if $route.query object is empty (URL does not contains search params), but there are filters already selected in Vuex
      this.updateRouterQueriesForURL();
      this.$store.commit('SET_SEARCH_MODE', true);
      await this.debouncedFetchResourcesWithClient()
    }
    this.$store.commit('SET_COLLECTION_VIEW', false);

    // get SavedSearches when this view component is mounted
    await this.$store.dispatch('saveSearches/getSavedSearchesById', this.$store.state.uid);
  },
  methods: {
    updateSearchModeAndFetch() {
      this.$nextTick(() => {
        console.log('updateSearchModeAndFetch...')
        if (!this.$store.getters.isFiltersEmpty || (this.$store.state.keywords && this.$store.state.keywords.length >= 3)) {
          this.$store.commit('SET_SEARCH_MODE', true);
        } else {
          this.$store.commit('SET_SEARCH_MODE', false);
        }
        this.debouncedFetchResourcesWithClient()
        this.updateRouterQueriesForURL();
      });
    },
    fetchResourcesWithClient() {
      this.$store.dispatch('fetchResourcesWithClient');
    },
    getCardSize(val) {
      this.cardSize = val;
    },
    toggleView(val) {
      if (val === 0) {
        this.showGridView = true;
      }
      if (val === 1) {
        this.showGridView = false;
      }
    },
    handleError(val) {
      this.searchFieldError = val;
    },
    mapFilterMetadata(filter) {
      let mappedMetadata;
      switch (filter) {
        case 'urhlibrary':
          mappedMetadata = this.$store.state.metadata.filtersMetadata.urhlibrary.map(e => e.id);
          break;
        case 'library':
          mappedMetadata = this.$store.state.metadata.filtersMetadata.library.map(e => e.id);
          break;
        case 'years':
          mappedMetadata = this.$store.state.metadata.filtersMetadata.years.map(e => e.id);
          break;
        case 'klas':
          mappedMetadata = this.$store.state.metadata.filtersMetadata.klas.map(e => e.id);
          break;
        case 'resource-type':
          mappedMetadata = this.$store.state.metadata.filtersMetadata.type.map(e => e.id);
          break;
        case 'phase':
          mappedMetadata = this.getAllChildFilters('metadata/phases')?.map(e => e.id);
          break;
        case 'resourcetype':
          mappedMetadata = this.getAllChildFilters('metadata/hubTypes')?.map(e => e.id);
          break;
        case 'focusarea':
          mappedMetadata = this.getAllChildFilters('metadata/focusArea')?.map(e => e.id);
          break;
        case 'sefalignment':
          mappedMetadata = this.getAllChildFilters('metadata/sefAlignment')?.map(e => e.id);
          break;
        case 'source':
          mappedMetadata = this.$store.state.metadata.filtersMetadata.sources.map(e => e.id);
          break;
        case 'resource-category':
          mappedMetadata = this.$store.state.metadata.filtersMetadata.resourcecategories.map(e => e.id);
          break;
        case 'randntopics':
          mappedMetadata = this.$store.state.metadata.filtersMetadata.randntopics.map(e => e.id);
          break;
        case 'sort':
          mappedMetadata = this.sortOptions.map(e => e.value);
      }
      return mappedMetadata;
    },
    getAllChildFilters(metadata) {
      return this.$store.getters[metadata]?.reduce(function (previousValue, currentValue) {
        return [...previousValue, ...currentValue.children]
      }, [{}]);
    },
    validateQuery([query, value]) {
      if (!this.queriesOptions.includes(query)) {
        return false;
      }
      if (query === 'search') {
        return value.length > 2;
      }
      if (query === 'ref') {
        return value.length > 1;
      }
      if (!value) {
        return false
      }
      value = value.split(',');

      let checkedValue = value.filter(v => this.mapFilterMetadata(query).includes(v));
      return checkedValue.length > 0 && checkedValue;
    },
    handleQueryParamsFromURL() {
      let filters = {...HUB_FILTERS_STRUCTURE};
      let query = {...this.$route.query}; // e.g. "/home?search=school&years=YEAR_K&source=readingandnumeracy&sort=relevance"
      // check each route query and delete it if not valid
      for (const [key, value] of Object.entries(query)) {
        if (!this.validateQuery([key, value])) {
          delete query[key];
        }
      }

      console.log('handleQueryParamsFromURL', query)

      // if only sort query is left, it should be deleted, hence no queries would be left and the above is applied
      if (Object.keys(query).length === 1 && query.sort) {
        delete query.sort;
        this.$store.commit('SET_SEARCH_MODE', false);
      } else if (Object.keys(query).length > 1 && !query.sort) {
        query.sort = 'relevance';
      }

      this.$store.commit('SET_KEYWORDS', '');

      // map route query to state.filters and perform a search
      if (Object.keys(query).length > 0) {
        Object.entries(query).forEach(([key, value]) => {
          switch (key) {
            case 'ref': // this param is for tracking external traffic to URH
              this.$store.commit('SET_SEARCH_REFERENCE', query[key]);
              break;
            case 'search':
              this.$store.commit('SET_KEYWORDS', query[key]);
              break;
            case 'sort':
              this.$store.commit('SET_SORT', query[key]);
              break;
            case 'urhlibrary':
              filters['urhlibrary'] = this.validateQuery([key, value]).join(',');
              break;
            case 'library':
              filters['library'] = this.validateQuery([key, value]).join(',');
              break;
            case 'years':
              filters['resource-years'] = this.validateQuery([key, value]).join(',');
              break;
            case 'klas':
              filters['resource-klas'] = this.validateQuery([key, value]).join(',');
              break;
            case 'phase':
              filters['phase'] = this.validateQuery([key, value]).join(',');
              break;
            case 'resourcetype': // hubTypes in stores
              filters['resourcetype'] = this.validateQuery([key, value]).join(',');
              break;
            case 'focusarea':
              filters['focusarea'] = this.validateQuery([key, value]).join(',');
              break;
            case 'sefalignment':
              filters['sefalignment'] = this.validateQuery([key, value]).join(',');
              break;
            case 'resource-type':
              filters['resource-types'] = this.validateQuery([key, value]).join(',');
              break;
            case 'source':
              filters['resource-sources'] = this.validateQuery([key, value]).join(',');
              break;
            case 'randntopics':
              filters['resource-randntopics'] = query[key];
              break;
            case 'resource-category':
              filters['resource-category'] = this.validateQuery([key, value]).join(',');
              break;
          }
        });
      }

      if (filters['resource-sources'] && filters['resource-sources'].split(',').includes('readingandnumeracy')) {
        this.$store.commit('SET_IS_DIRECT_ACCESS', true);
      }
      // set filters params from URL to Vuex
      this.$store.commit('SET_FILTERS', filters);

      let filtersSelection = {...HUB_FILTERS_SELECTION_STRUCTURE};
      filtersSelection = {
        checkboxResourceCategory : filters['resource-category'] === '' ? [] : filters['resource-category']?.split(',') || [],
        checkboxUrhLibrary: filters['urhlibrary'] ? filters['urhlibrary'].split(',') : [],
        checkboxYears: filters['resource-years'] ? filters['resource-years'].split(',') : [],
        checkboxKLAs: filters['resource-klas'] ? filters['resource-klas'].split(',') : [],
        checkboxResourceType: filters['resource-types'] ? filters['resource-types'].split(',') : [],
        checkboxSource: filters['resource-sources'] ? filters['resource-sources'].split(',') : [],
        checkboxRandN: filters['resource-randntopics'] ? filters['resource-randntopics'].split(',') : [],
        // new in R4:
        checkboxFormat: filters.formats ? filters.formats.split(',') : [],
        checkboxHubType: filters.resourcetype ? filters.resourcetype.split(',') : [],
        checkboxPhase: filters.phase ? filters.phase.split(',') : [],
        checkboxSefAlignment: filters.sefalignment ? filters.sefalignment.split(',') : [],
        checkboxFocusArea: filters.focusarea ? filters.focusarea.split(',') : []
      };
      this.$store.commit('SET_FILTERS_SELECTION', filtersSelection);

      // cleanup tracking ref url after the first read
      if (query.ref) delete query.ref;
      this.$router.replace({query}).catch(() => {});
    },
    // for any query params change on the UI, update $router so these query params can be added in the URL;
    // should not do any api calls in this method as it just updates $route.query
    updateRouterQueriesForURL() {
      // this is a fix to identify the queries are updated internally to avoid executing same multiple time
      this.queriesUpdatedInternally = true;
      let query = {...this.$route.query};

      // search
      if (this.searchString.length > 2) {
        query.search = this.searchString;
      } else {
        delete query.search;
      }
      // filters
      for (let [key, value] of Object.entries(this.$store.state.filters)) {
        switch (key) {
          case 'resource-category':
            if (value !== '') query['resource-category'] = value;
            else delete query['resource-category'];
            break;
          case 'library':
            if (value !== '') query.library = value;
            else delete query.library;
            break;
          case 'urhlibrary':
            if (value !== '') query.urhlibrary = value;
            else delete query.urhlibrary;
            break;
          case 'resource-years':
            if (value !== '') query.years = value;
            else delete query.years;
            break;
          case 'resource-klas':
            if (value !== '') query.klas = value;
            else delete query.klas;
            break;
          case 'resource-types':
            if (value !== '') query['resource-type'] = value;
            else delete query['resource-type'];
            break;
          case 'resource-sources':
            if (value !== '') query.source = value;
            else delete query.source;
            break;
          case 'resource-randntopics':
            if (value === '' || !(query.source.includes('readingandnumeracy') || query.source.includes('literacyandnumeracypackages')))
              delete query.randntopics;
            else query.randntopics = value;
            break;
          // new in R4:
          case 'formats':
            if (value !== '') query['formats'] = value;
            else delete query['formats']
            break;
          case 'resourcetype':
            if (value !== '') query['resourcetype'] = value;
            else delete query['resourcetype']
            break;
          case 'phase':
            if (value !== '') query['phase'] = value;
            else delete query['phase']
            break;
          case 'sefalignment':
            if (value !== '') query['sefalignment'] = value;
            else delete query['sefalignment']
            break;
          case 'focusarea':
            if (value !== '') query['focusarea'] = value;
            else delete query['focusarea']
            break;
        }
      }

      // sort
      if (query.sort) {
        delete query.sort;
      }
      if (Object.keys(query).length !== 0) {
        // query.sort = this.$store.state.sort;
        if (this.$store.state.sort.direction) {
          query.sort = this.$store.state.sort.field + ':' + this.$store.state.sort.direction;
        } else {
          query.sort = this.$store.state.sort.field;
        }
      }

      // remove search mode if there's no queries or search scope unchanged
      this.$store.commit('SET_SEARCH_MODE', Object.keys(query).length !== 0);

      if (this.$store.state.searchMode) {
        // reset search screen
        this.$store.commit('CLEAR_SEARCH');
      }

      // replace $router query to display query params on the URL
      this.$router.replace({query}).catch(() => {});
    },
    async handleClearAllFilters() {
      await this.$store.commit('CLEAR_ALL_FILTERS');
      if (this.$store.state.keywords && this.$store.state.keywords.length >= 3) {
        this.$store.commit('SET_SEARCH_MODE', true);
        this.debouncedFetchResourcesWithClient()
      } else {
        this.$store.commit('SET_SEARCH_MODE', false);
        this.debouncedFetchResourcesWithClient()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

.search__wrapper {
  display: flex;
  flex-direction: column;

  .search__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    ::v-deep .search-bar {
      //margin-right: 24px;
      flex: 1;

      @media only screen and (min-width: 620px) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .v-label {
        color: $ads-dark-60;
      }

      &.v-text-field--outlined {
        &:not(.v-input--is-focused) {
          &:not(.v-input--has-state) > {
            .v-input__control {
              .v-input__slot {
                fieldset {
                  color: $ads-light-40;
                }
              }
            }
          }
        }
      }
    }

    .category-select {
      min-width: 240px;
      max-width: 250px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px; // to fix the left border width
      ::v-deep .v-input__slot {
        background: $ads-white;
      }
    }
  }
}

.chip-filters__wrapper {
  display: flex;
  flex-wrap: nowrap;
  width: calc(100% + 12px);
  overflow-x: scroll;
}

.chip-filters__wrapper::-webkit-scrollbar {
  display: none;
}

.clear-all {
  &:hover {
    &:before {
      opacity: 0 !important;
    }
  }

  &:focus {
    outline-color: $ads-navy !important;
  }
}

.v-menu {
  &__content {
    max-width: 290px;
  }
}

::v-deep .v-card {
  &__title {
    .heading {
      width: 100px;
      word-break: keep-all;
      line-height: 1.5;
      margin-right: 5px;
    }

    button.close-button {
      align-self: flex-start;
    }
  }
}

@media only screen and (max-width: 620px) {
  .search__wrapper {
    .search__container {
      display: flex;
      flex-direction: column;
      margin: 0 12px;

      .search-bar {
        width: 100%;
      }
    }
  }

  .chip-filters__wrapper {
    .col {
      &:first-child {
        margin-left: 12px;
      }

      &:last-child {
        margin-right: 12px;
      }
    }
  }
}

.resources-heading__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px 0;

  .resources-heading__left-content {
    margin-bottom: 0;
  }

  .resources-heading__right-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .sorting {
      display: flex;
      flex-direction: row;
      align-items: center;

      #sorting-label {
        padding-right: 12px;
      }

      .sorting-menu {
        max-width: 180px;
        padding-right: 24px;
      }
    }
  }
}

@media only screen and (max-width: 620px) {
  .resources-heading__wrapper {
    .resources-heading__left-content {
      padding-left: 12px;
    }

    .resources-heading__right-content {
      .sorting {
        .sorting-menu {
          padding-right: 12px;
        }
      }
    }
  }
}
</style>
