<template>
  <div class="resource-editor-table-wrapper">
    <v-card class="mt-5">
      <AdsDataTable
        :page.sync="$store.state.editor.pageNum"
        :options.sync="pagination"
        :items-per-page.sync="$store.state.editor.perPage"
        class="hub-resources-table"
        :value="value"
        :headers="tableHeaders"
        :items="selectableItems"
        item-key="ItemID"
        sort-by="updated"
        sort-desc
        :show-select="canEditResources"
        :loading="$store.state.isLoading"
        loading-text="Searching... Please wait"
        :server-items-length="count"
        must-sort
        :footer-props="{
          'items-per-page-options': [16, 32, 48],
          'page-text': '{0} to {1} out of {2}'
        }"
        @input="value => $emit('input', value)"
        @click:row="handleRowSelected"
      >
        <template #top>
          <v-row
            class="mx-3 mb-1"
            justify="center"
            align="center"
          >
            <v-col md="9">
              <v-text-field
                v-model="searchString"
                class="pt-0"
                clearable
                label="Search"
                aria-label="search"
                prepend-icon="search"
                hide-details
              />
            </v-col>
            <v-col class="hub-resources-table__showing">
              <label id="status-label">Showing:</label>
              <v-select
                v-model="status"
                class="pt-0"
                aria-labelledby="status-label"
                hide-details
                :items="resourceStatusList"
                :dense="false"
              />
            </v-col>
          </v-row>
          <v-divider />
        </template>
        <template #item.id="{item}">
          <div class="truncated-id">
            {{ item.ItemID }}
          </div>
        </template>
        <template #item.updated="{item}">
          <div class="updated">
            {{ item.DateModified | dateFormat }}
          </div>
        </template>
        <template #item.title="{item}">
          <div class="truncated-title">
            {{ item.Name }}
          </div>
        </template>
        <template #item.category="{item}">
          <div
            v-if="item.IsResourceCollection"
            class="category category__collection"
          >
            <FeatureIcon
              icon="mdi-bookmark-multiple-outline"
              size="24"
              backgroundColor="white"
              ariaLabel="Collection"
            />
          </div>
          <div
            v-else
            class="category category__resource"
          >
            <FeatureIcon
              v-for="cat in item.ResourceCategory"
              :key="cat"
              size="24"
              :aria-label="getCategoryDetails(cat).label"
              :color="getCategoryDetails(cat).iconColor"
              :backgroundColor="getCategoryDetails(cat).iconBg"
              :icon="getCategoryDetails(cat).icon"
            />
          </div>
        </template>
        <template #item.status="{item}">
          <div>
            <Chip
              chipClass="status-chip"
              small
              :text="item.Status.toUpperCase()"
              :color="statusChipColor(item.Status)"
              :outlined="statusChipOutlined(item.Status)"
              :textColor="statusChipTextColor(item.Status)"
            />
            <ActionsMenu
              v-if="!hasViewerAccess(item.Source)"
              class="actions-menu"
              icon
              :items="actions(item)"
              :externalData="item"
              @clickedAction="handleAction"
            />
          </div>
        </template>
      </AdsDataTable>
      <DeleteResource
        v-model="deleteDialog"
        :resource="currentResource"
        @confirm="handleDelete"
      />
      <ErrorDialog
        v-model="showDeleteError"
        title="You do not have permissions to delete this resource"
        :resource-id-exist="false"
      >
        <template #message>
          <p>Please contact the resource owner should you have any concerns.</p>
        </template>
        <template #buttons>
          <AdsButton
            button-text="Close"
            @click="$downwardBroadcast('close:dialog')"
            @keyup.enter="$downwardBroadcast('close:dialog')"
          />
        </template>
      </ErrorDialog>
    </v-card>
    <SaveToCollectionDialog
      v-model="showSaveToCollectionDialog"
      :selected-cards="[currentResource]"
      hubCollection
      @onReviewSubmitted="handleReviewSubmitted"
    />
    <SubmitForReviewDialog
      v-model="showSubmitForReview"
      hubCollection
      :application="currentResource.Application"
      :source="currentResource.Source"
      :recordId="currentResource.ItemID"
      @onReviewSubmitted="handleReviewSubmitted"
    />
  </div>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import {AdsButton, AdsDataTable, Chip, FeatureIcon} from '@nswdoe/doe-ui-core';
import {RESOURCE_CATEGORIES, DATATABLE_ITEMS_STATUS} from '@/constants'
import moment from 'moment';
import ActionsMenu from '@/views/MyResourceDetailsPage/ActionsMenu/ActionsMenu'
import DeleteResource from '@/components/Dialogs/Editor/DeleteResource'
import {ErrorDialog} from '@/components/Dialogs/Collections'
import _isEqual from 'lodash/isEqual'
import _debounce from 'lodash/debounce'
import statusChipMixin from '@/mixins/statusChipMixin';
import SaveToCollectionDialog from '@/components/Dialogs/Collections/SaveToCollection';
import SubmitForReviewDialog from '@/components/Dialogs/Editor/SubmitForReviewDialog';

export default {
  name: 'EditorTable',
  components: {
    ActionsMenu,
    AdsDataTable,
    Chip,
    DeleteResource,
    FeatureIcon,
    ErrorDialog,
    AdsButton,
    SaveToCollectionDialog,
    SubmitForReviewDialog
  },
  filters: {
    dateFormat(value) {
      return moment(value).format('DD-MM-YYYY');
    }
  },
  mixins: [statusChipMixin],
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      moment,
      showErrorDialog: false,
      errorMessages: '',
      pagination: {},
      sortedFilteredResources: [],
      currentResource: {},
      singleSelect: true,
      deleteDialog: false,
      outlined: false,
      tableHeaders: [
        {
          text: 'ID',
          value: 'id',
          width: 150
        },
        {
          text: 'Updated',
          value: 'updated',
          width: 120
        },
        {
          text: 'Title',
          value: 'title',
          width: 250
        },
        {
          text: 'Content owner',
          value: 'Source',
          width: 100
        },
        {
          text: 'Category',
          value: 'category',
          width: 146
        },
        {
          text: 'Created by',
          value: 'CreatedBy',
          width: 140
        },
        {
          text: 'Reviewed by',
          value: 'AssignedTo',
          width: 152
        },
        {
          text: 'Status',
          value: 'status',
          width: 160
        }
      ],
      showSaveToCollectionDialog: false,
      showSubmitForReview: false,
    }
  },
  computed: {
    ...mapState({
      status: state => state.status,
    }),
    ...mapGetters({
      allSources: 'metadata/sources',
      items: 'editor/items',
      count: 'editor/count',
      userProfile: 'users/userProfile',
      profileSourcesIds: 'users/profileSourcesIds',
      resourceStatusList: 'metadata/resourceStatusList',
      isReviewer: 'users/isReviewer',
      isAuthor: 'users/isAuthor',
      isPublisher: 'users/isPublisher',
      hasPublisherAccessTo: 'users/hasPublisherAccessTo',
      hasReviewerAccessTo: 'users/hasReviewerAccessTo',
      hasAuthorAccessTo: 'users/hasAuthorAccessTo',
      hasViewerAccessTo: 'users/hasViewerAccessTo',
      scope: 'scope',
    }),
    canEditResources() {
      return this.isAuthor || this.isReviewer || this.isPublisher;
    },
    selectableItems() {
      return this.items.map(item => ({
        ...item,
        isSelectable: this.selectable(item),
        ...(item.IsCollection && {
          ResourceCategory: Array(item.ResourceCategory.length || 1).fill('collection')
        })
      }));
    },
    searchString: {
      get() {
        return this.$store.state.editor.keywords;
      },
      set(value) {
        this.$store.commit('editor/SET_KEYWORDS', value);
        this.debouncedFetchResources();
      }
    },
    status: {
      get() {
        return this.$store.state.editor.status;
      },
      set(value) {
        this.$store.commit('editor/SET_STATUS', value);
        // add delay to avoid multiple api calls when state is updating
        this.debouncedFetchResources();
      },
    },
    showDeleteError: {
      get() {
        return this.$store.state.editor.errorState
      },
      set(value) {
        this.$store.commit('editor/SET_DISPLAY_ERROR', value)
      }
    }
  },
  watch: {
    pagination: {
      handler(newVal, oldVal) {
        if (newVal && oldVal && _isEqual(newVal, oldVal)) {
          // don't clear the filters ( back to list view button)
          return
        }
        this.fetchResources()
        this.$emit('onPaginationChange', this.pagination);
      },
      deep: true
    },
    showSaveToCollectionDialog(open) {
      if (!open) {
        // Need to refetch resources when new collection added
        this.$store.dispatch('editor/fetchResources', this.pagination);
      }
    }
  },
  created() {
    // no more than once every 2 seconds on change of keyword
    this.debouncedFetchResources = _debounce(this.fetchResources, 500);
  },
  methods: {
    hasPublisherAccess(contentArea) {
      return this.checkAccess(this.hasPublisherAccessTo, contentArea)
    },
    hasReviewerAccess(contentArea) {
      return this.checkAccess(this.hasReviewerAccessTo, contentArea)
    },
    hasAuthorAccess(contentArea) {
      return this.checkAccess(this.hasAuthorAccessTo, contentArea)
    },
    hasViewerAccess(contentArea) {
      return this.checkAccess(this.hasViewerAccessTo, contentArea)
    },
    checkAccess(accesses, contentArea) {
      return accesses.findIndex(ca => ca.id === contentArea) > -1
    },
    disableDeleteAction(item) {
      // https://jira.education.nsw.gov.au/browse/DLRHUB-1899
      // Only draft resource cards can be deleted
      // An author can only delete collections they have created themselves AND that are in DRAFT or DECLINED Status only
      // A Viewer cannot delete any collections
      // A reviewer can only delete collections they have created themselves that are in DRAFT or DECLINED Status only
      // A publisher can delete any collection in the groups that are in the DRAFT or DECLINED status only
      switch (true) {
        case !(item.Status === DATATABLE_ITEMS_STATUS.DRAFT || item.Status === DATATABLE_ITEMS_STATUS.DECLINED):
          return { disable: true, tooltip: "You can only delete 'draft' or 'declined' items" };
        case this.hasPublisherAccess(item.Source) && (item.Status === DATATABLE_ITEMS_STATUS.DRAFT):
          return { disable: false };
        case this.hasPublisherAccess(item.Source) && (item.Status === DATATABLE_ITEMS_STATUS.DECLINED):
          return { disable: false };
        case item.CreatedBy === this.userProfile.userId && (item.Status === DATATABLE_ITEMS_STATUS.DRAFT || item.Status === DATATABLE_ITEMS_STATUS.DECLINED):
          return { disable: false };
        case this.hasAuthorAccess(item.Source):
        case this.hasReviewerAccess(item.Source): {
          const disable = item.CreatedBy !== this.userProfile.userId;
          return {
            disable,
            ...(disable && { tooltip: 'You may only delete your own collections' })
          };
        }
        default:
          return { disable: true };
      }
    },
    actions(item) {
      // https://jira.education.nsw.gov.au/browse/DLRHUB-1900
      // ‘Add to collection’ button option should only be usable when ... ‘published’ status.
      // ‘Unpublish’ button option should only be usable when ... ‘published’ status.
      // The unpublish button option can only be used by “publisher” role.
      // ‘Submit for review’ button __ option should only be usable when ‘draft’
      return [
        {
          id: 'addToCollection',
          name: 'Add to collection',
          tag: 'button',
          icon: 'mdi-bookmark-plus-outline',
          ...(item.Status !== DATATABLE_ITEMS_STATUS.PUBLISHED && {
            disable: true,
            tooltip: 'Only published content can be added to collections'
          }),
          ...(item.Application === 'equ' && {
            disable: true,
            tooltip: 'Only URH or OLP contents can be added to collections'
          })
        },
        !this.hasPublisherAccess(item.Source) && item.Status === DATATABLE_ITEMS_STATUS.DRAFT && {
          id: 'submitForReview',
          name: 'Submit for review',
          tag: 'button',
          icon: 'mdi-arrow-right-bold-box-outline',
          ...((item.Application === 'equ') && {
            disable: true
          })
        },
        // this.hasPublisherAccess(item.Source) && item.Status === DATATABLE_ITEMS_STATUS.PUBLISHED && {
        //   id: 'unpublish',
        //   name: 'Unpublish',
        //   tag: 'button',
        //   icon: 'mdi-undo'
        // },
        this.hasPublisherAccess(item.Source) && (item.Status === DATATABLE_ITEMS_STATUS.DRAFT || item.Status === DATATABLE_ITEMS_STATUS.ENDORSED) && { // publisher can only delete from draft status or endorsed
          id: 'publish',
          name: 'Publish',
          tag: 'button',
          icon: 'mdi-publish',
          ...(item.NumSubResources < 2 && {
            disable: true,
            tooltip: 'Cannot publish collections with less than 2 resources'
          })
        },
        {
          id: 'delete',
          name: 'Delete',
          tag: 'button',
          icon: 'mdi-delete-outline',
          ...this.disableDeleteAction(item)
        }
      ].filter(Boolean);
    },
    fetchResources() {
/*      // set filters from user profile before calling the api
      const filtersSelection = {...this.$store.state.editor.filtersSelection};
      filtersSelection.checkboxOwner = this.profileSourcesIds;
      this.$store.commit('editor/SET_FILTERS_SELECTION', filtersSelection);

      const newFilters = {...this.$store.state.editor.filters};
      newFilters.owner = this.profileSourcesIds.join();
      this.$store.commit('editor/SET_FILTERS', newFilters);*/

      this.$store.dispatch('editor/fetchResources', this.pagination);
    },
    getOwnerDetails(source) {
      // if the filters are not loaded yet
      if (this.allSources.length === 0) {
        return source;
      }
      // Lookup in metadata for the source label
      return this.allSources?.find(type => {
        return type.id === source
      })?.name || '';
    },
    getCategoryDetails(category) {
      return RESOURCE_CATEGORIES.find(cat => cat.id === category) || null;
    },
    handleRowSelected(resource) {
      if (resource.IsResourceCollection) {
        this.$router.push({name: 'CollectionBoardPage',
          params: {
            id: resource.ItemID,
            application: resource.Application,
            source: resource.Source,
            rce: true
          },
          query: {
            rce: true
          }
        });
      } else {
        this.$router.push({name: 'ResourceEditorDetailsPage', params: { id: resource.ItemID, application: resource.Application, source: resource.Source }});
      }
    },
    amITheOwner(owner) {
      return true
      // return owner.includes(this.$store.getters['users/userProfile'].userId.toLowerCase());
    },
    selectable(item) {
      return true;
      // return item.Status === DATATABLE_ITEMS_STATUS.PUBLISHED;
    },
    isSelectableCollection(item) {
      return item.IsResourceCollection && !this.selectable(item);
    },
    async publish(published) {
      const payload = {
        isPublished: published,
        scope: this.scope,
        documents: [this.currentResource],
        displayAlert: false,
        owner: this.currentResource.CreatedBy,
      };
      console.log('publishUnPublishResourcesWithData payload', payload)
      await this.$store.dispatch('editor/publishUnPublishResourcesWithData', payload);
      await this.$store.dispatch('editor/fetchResources', this.pagination);
    },
    async handleDelete(resource) {
      let payload = {
        resourceId: resource.ItemID,
        repository: resource.Source,
        application: resource.Application || this.currentResource.Application,
        displayAlert: true
      }
      const response = await this.$store.dispatch('editor/deleteResource', payload);
      if (response) {
        this.fetchResources()
      }
    },
    async handleCopy(resource) {
      let payload = {
        resourceId: resource.ItemID,
        resourceName: resource.Name,
        repository: resource.Source,
        displayAlert: true
      }
      const newResource = await this.$store.dispatch('editor/copyResource', payload);

      if (newResource) {
        await this.$router.push(`resourceeditor/${newResource.record.id}`);
        await this.$store.commit('editor/SET_EDIT_MODE', true)
      }
    },
    handleAction({action, externalData}) {
      this.currentResource = {...externalData}
      switch (action) {
        case 'duplicate':
          this.handleCopy(externalData)
          console.log('duplicate action...')
          break;
        case 'delete':
          console.log('delete action...')
          if (this.amITheOwner(externalData.Owners || [])) {
            console.log(JSON.stringify(externalData))
            this.deleteDialog = true;
          } else {
            this.showErrorDialog = true;
            this.errorMessages = 'There was an error deleting this resource'
            const alert = {
              type: 'error',
              text: 'There was an error deleting this resource'
            }
            this.$store.commit('SET_ALERT', alert, {root: true});
            this.$store.commit('SET_DISPLAY_ALERT', true);
          }
          break;
        case 'addToCollection':
          this.showSaveToCollectionDialog = !this.showSaveToCollectionDialog;
          break;
        case 'submitForReview':
          this.$store.dispatch('editor/getReviewersList', externalData.Source);
          this.showSubmitForReview = true;
          break;
        case 'unpublish':
          this.publish(true);
          break;
        case 'publish':
          this.publish(false);
          break;
      }
    },
    handleReviewSubmitted() {
      console.log('fetching resources', this.pagination)
      this.$store.dispatch('editor/fetchResources', this.pagination);
      this.showSubmitForReview = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.resource-editor-table-wrapper {
  width: 100%;

  @media only screen and (min-width: 1600px) {
    width: calc(100% + 32px);
  }

  ::v-deep .hub-resources-table.dataTable.v-data-table table {
    .v-data-table-header {
      th {
        &:first-child {
          padding-left: 22px;
        }

        &:nth-child(2) {
          padding-left: 0;
        }

        &:last-child {
          padding: 0;
        }
      }

      .v-btn {
        min-width: initial;
      }
    }

    tbody {
      td {
        font-size: 16px;
        cursor: pointer;

        &:first-child {
          padding-left: 22px;
          min-width: unset;
        }

        &:last-child {
          padding-inline: 0 10px;
        }

        &:has(.updated) {
          padding-left: 0;
          font-weight: bold;
        }

        .category {
          display: flex;
          align-items: center;

          &__collection::after {
            content: 'Collection';
            margin-left: 16px;
          }

          &__resource::after {
            content: 'Resource';
            margin-left: 16px;
          }
        }

        .v-chip__content {
          margin-inline: auto;
          font-size: 14px;
          font-weight: bold;
        }

        .actions-menu > div {
          left: unset !important;
          right: 48px;
        }
      }
    }
  }

  .hub-resources-table__showing {
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    column-gap: 20px;
  }

  .truncated-title {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .truncated-id {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .status-chip {
    width: 107px;
  }
}
</style>
