<template>
  <v-menu
    v-model="showMenu"
    class="go-to-resources"
    offset-y
    bottom
    :left="alignRight"
    :right="alignLeft"
    :nudge-bottom="8"
    transition="slide-y-transition"
    origin="top right"
    attach
  >
    <template #activator="{ on, attrs }">
      <AdsButton
        small
        v-bind="attrs"
        :primary="primary"
        :secondary="secondary"
        :buttonText="buttonText"
        :icon="icon"
        :color="ADS_Colors.Navy"
        ariaLabel="Share"
        :class="{'go--to--resource-button': true, 'go--to--resource__mobile': $vuetify.breakpoint.xsOnly, 'btn-color': primary === true}"
        v-on="on"
        @click="showMenu = true"
      />
    </template>
    <GoToResourceList
      :id="id"
      :items="items"
      :item-url="itemUrl"
      :name="name"
      :source="source"
    />
  </v-menu>
</template>

<script>
import GoToResourceList from '@/components/GoToResource/GoToResourceList';
import { ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'GoToResources',
  components:{
    GoToResourceList,
    AdsButton
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemUrl: String, // ResourceURL
    id: String, // ItemId of the resource card
    name: String, // Name of the resource card,
    source: {
      type: String,
      default: ''
    },
    alignLeft: {
      type: Boolean,
      default: true
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    buttonText:{
      type: String,
      default: 'Button Name'
    },
    icon:{
      type: String,
      default: ''
    },
    primary:{
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      showMenu: false,
      ADS_Colors
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";

.go--to--resource__mobile {
  width: 100% !important;
}

.go--to--resource-button {
  width: 100%;
  min-width: 160px !important;
  //color: $ads-white !important;
  &:focus-visible {
    outline: none !important;
  }
}
.btn-color {
  color: $ads-white !important;
}

.go-to-resources > div {
  max-height: 500px;
}
</style>

