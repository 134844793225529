<template>
  <v-row class="ml-2">
    <v-col
      v-for="(item, index) in items"
      :key="index"
      cols="12"
      class="py-0 mb-6"
    >
      <v-responsive
        v-if="loading"
        min-width="80%"
        min-height="300px"
      >
        <v-skeleton-loader
          boilerplate
          type="card, image, list-item-three-line"
        />
      </v-responsive>
      <ResourceListCard
        v-else
        v-bind="$attrs"
        :item="item"
        :show-checkbox="selectable"
        :show-recently-added-badge="true"
        :isSelected="isSelected(item.resourceId.id)"
        :sequence-order="showSequence"
        :item-index="getSequenceNumber(item.resourceId.id)"
        :item-length="items.length"
        v-on="$listeners"
      />
    </v-col>
  </v-row>
</template>

<script>
import ResourceListCard from '@/components/Resource/ResourceListCard'

export default {
  name: 'ResourceList',
  components: {
    ResourceListCard
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedCards: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: true
    },
    showSequence: {
      type: Boolean,
      default: false
    },
    sequence: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isSelected(id) {
      return !!this.selectedCards.find(item => item.resourceId.id === id)
    },
    getSequenceNumber(id) {
      return this.sequence?.findIndex((element) => element === id)
    }
  }

}
</script>
