<template>
  <div
    id="page__wrapper"
    class="page__wrapper"
  >
    <Banner
      id="main-content"
      :heading="banner.heading"
      :subtitle="banner.subtitle"
      :icon="banner.icon"
      :icon-size="banner.iconSize"
      tabindex="-1"
      class="collection-metadata"
    >
      <template #rightPannel>
        <div
          class="d-flex justify-space-between"
          :class="$vuetify.breakpoint.mdAndUp ? 'mt-5':'flex-column mt-6 pt-2 pl-2'"
        >
          <div>
            <template v-if="hubCollection && isPublished">
              <template v-if="!editorView">
                <Chip
                  v-if="isFollowing"
                  chipClass="status-chip px-8 mt-2"
                  small
                  style="font-weight: bold"
                  :textColor="ADS_Colors.Navy"
                  text="FOLLOWING"
                  :color="ADS_Colors.Blue_3"
                />
                <div
                  v-if="isFollowing"
                  class="ml-3"
                >
                  <AdsButton
                    primary
                    :color="ADS_Colors.Blue_3"
                    tertiary
                    :loading="loading"
                    button-text="Unfollow"
                    @click="unFollowCollection"
                    @keyup.enter="unFollowCollection"
                  />
                  <div class="ml-6">
                    <small>
                      {{ collection.followers?.length }} follower<span v-if="collection.followers?.length !== 1">s</span>
                    </small>
                  </div>
                </div>
                <div v-else>
                  <AdsButton
                    primary
                    :loading="loading"
                    color="#6CACE4"
                    icon="mdi-plus"
                    button-text="Follow"
                    @click="followCollection"
                    @keyup.enter="followCollection"
                  />
                  <div>
                    <small>
                      {{ collection.followers?.length }} follower<span v-if="collection.followers?.length !== 1">s</span>
                    </small>
                  </div>
                </div>
              </template>
              <Chip
                v-else
                chipClass="status-chip px-8"
                small
                :text="collection.statusLabel.toUpperCase()"
                :color="statusChipColor(collection.statusLabel, true)"
                :outlined="statusChipOutlined(collection.statusLabel, true)"
                :textColor="statusChipTextColor(collection.statusLabel)"
              />
            </template>
            <Chip
              v-else-if="hubCollection"
              chipClass="status-chip px-8"
              small
              :text="collection.statusLabel.toUpperCase()"
              :color="statusChipColor(collection.statusLabel, true)"
              :outlined="statusChipOutlined(collection.statusLabel, true)"
              :textColor="statusChipTextColor(collection.statusLabel)"
            />
          </div>
          <!-- hub/my collection actions (options menu) -->
          <template v-if="!isViewer || !editorView">
            <ActionsMenu
              v-if="$vuetify.breakpoint.mdAndUp"
              left
              offset-y
              :nudge-bottom="4"
              :items="editCollectionActions"
              :button="moreActionsButton"
              class="align-self-end mr-2"
              @clickedAction="handleMoreActionsMenu"
            />
            <!-- Floating create collection button -->
            <div v-else>
              <CircleActionMenu
                offset-x
                :nudge-bottom="10"
                :items="editCollectionActions"
                :button="moreActionsButton"
                @clickedAction="handleMoreActionsMenu"
              />
            </div>
          </template>

          <!-- My Collections member details -->
          <div
            v-if="myCollection"
            class="d-flex ml-2"
          >
            <v-avatar
              class="text-uppercase"
              color="success"
              size="40"
            >
              {{ collection.userAccess.owner[0].split('.').map(item => item.charAt(0)).join('') }}
            </v-avatar>
            <div class="small text-small mt-3 mr-5 ml-4 text-capitalize">
              Created by {{ collection.userAccess.owner[0].replace('.', ' ') }}
            </div>
          </div>
        </div>
      </template>
      <template #bottomPanel>
        <div class="chips ml-3 mt-2">
          <v-chip
            v-for="(learningArea, i) in collection.KLA"
            :key="i"
            color="#A4D1F8"
            outlined
            class="mr-2"
          >
            {{ learningArea }}
          </v-chip>
          <v-chip
            v-for="(stage) in stages"
            :key="stage"
            color="#A4D1F8"
            outlined
            class="mr-2"
          >
            {{ stage }}
          </v-chip>
          <v-chip
            v-for="(topic) in collection.Topic"
            :key="topic"
            color="#A4D1F8"
            outlined
            class="mr-2 mt-1"
          >
            {{ topic }}
          </v-chip>
        </div>
      </template>
    </Banner>
    <v-banner v-if="learningSequenceSet" color="#FFCE99">
      <h4>
        <v-tooltip
          bottom
          max-width="500"
        >
          <template #activator="{ on }">
            <v-icon class="mr-2" v-on="on">info_outline</v-icon>
          </template>
          <span>The author of this collection has defined a learning sequence as advice on the order the resources could be used. This order is identified by the number in the bottom right corner of each resource image</span>
        </v-tooltip>
        Learning sequence set by owner
      </h4>
    </v-banner>
    <v-row
      v-if="$vuetify.breakpoint.smAndUp"
      dense
      class="mt-0"
    >
      <v-col
        v-if="!showSequence"
        class="content__wrapper pa-10"
      >
        <!-- We need to re enable this v-container when we implement our discussions panel-->
        <!-- <v-container> -->
        <v-row dense>
          <v-col>
            <div class="d-flex justify-space-between mt-5">
              <AdsButton
                tertiary
                icon="mdi-arrow-left"
                :class="{'mt-3': $vuetify.breakpoint.mdAndDown}"
                :small="$vuetify.breakpoint.mdAndDown"
                :disabled="loading"
                aria-label="go back"
                button-text="Back"
                @click="$router.go(-1)"
              />
              <template>
                <div class="d-flex justify-end">
                  <template v-if="showEditorView">
                    <!-- Viewer actions -->
                    <!-- no actions -->

                    <!-- Author actions -->
                    <!-- Submit for Review Btn -->
                    <template v-if="isAuthor">
                      <template v-if="isDraft || isDeclined">
                        <SubmitForReviewButton
                          :disabled="loading"
                          @click="submitForReviewDialog = true; $store.dispatch('editor/getReviewersList', collection.source)"
                        />
                      </template>
                    </template>

                    <!-- Reviewer actions -->
                    <!-- Endorse/Decline btns-->
                    <template v-if="isReviewer">
                      <template v-if="isDraft || isDeclined">
                        <SubmitForReviewButton
                          :disabled="loading"
                          @click="submitForReviewDialog = true; $store.dispatch('editor/getReviewersList', collection.source)"
                        />
                      </template>
                      <template v-if="isPending">
                        <DeclineButton
                          :disabled="loading"
                          @click="showDeclineDialog = true; $store.dispatch('editor/getReviewersList', collection.source)"
                        />
                        <EndorseButton
                          :disabled="loading"
                          @click="showEndorseDialog = true; $store.dispatch('editor/getReviewersList', collection.source)"
                        />
                      </template>
                    </template>

                    <!-- Publisher actions -->
                    <!-- Publish/Unpublish btns -->
                    <template v-if="isPublisher">
                      <template v-if="isPending">
                        <DeclineButton
                          :disabled="loading"
                          @click="showDeclineDialog = true"
                        />
                        <EndorseButton
                          :disabled="loading"
                          @click="showEndorseDialog = true; $store.dispatch('editor/getReviewersList', collection.source)"
                        />
                      </template>
                      <template v-else-if="isPublished">
                        <UnpublishButton
                          :disabled="loading"
                          @click="handlePublish" />
                      </template>
                      <template v-else>
                        <DeclineButton
                          v-if="isEndorsed"
                          @click="showDeclineDialog = true"
                        />
                        <v-tooltip
                          v-if="resources.length < 2"
                          bottom
                        >
                          <template #activator="{on, attrs}">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <PublishButton :disabled="true" />
                            </span>
                          </template>
                          <span>Cannot publish collection with less than 2 resources</span>
                        </v-tooltip>
                        <PublishButton v-else :disabled="loading" @click="handlePublish" />
                      </template>
                    </template>
                  </template>
                  <!-- copy/move/delete resources actions -->
                  <ActionsMenu
                    v-if="enableResourceSelections && collectionResourceActions.length > 0"
                    left
                    offset-y
                    :nudge-bottom="4"
                    :items="collectionResourceActions"
                    :button="editModeButton"
                    :disabled="selectedCards && selectedCards.length === 0"
                    @clickedAction="handleCollectionActionsMenu"
                  />
                </div>
              </template>
            </div>
          </v-col>
        </v-row>

        <!-- SEARCH By NAME -->
        <v-row :class="{'ml-2': !$vuetify.breakpoint.xsOnly}">
          <v-col>
            <AdsTextField
              v-model="searchString"
              prepend-inner-icon="search"
              clearable
              flat
              class="search-bar"
              label="Search within the collection"
              aria-label="Search within the collection"
              placeholder="Search within the collection"
              aria-placeholder="Search within the collection"
              aria-hidden="true"
              hide-details
            />
          </v-col>
        </v-row>
        <!-- SORTING panel -->
        <v-row
          class="my-5"
          justify="end"
        >
          <v-col
            v-if="!$vuetify.breakpoint.xsOnly && searchResults"
            sm="12"
            md="6"
            class="pl-8 pt-8"
          >
            <span v-if="selectedCards.length">{{ selectedCards.length }} of {{ searchResults.length }} resources selected</span>
            <span v-else>
              {{ searchResults.length }}
              <span v-if="searchString && searchString.length >= 3">results</span>
              <span v-else>resources</span>
            </span>
          </v-col>
          <v-col
            sm="12"
            md="6"
          >
            <div class="d-flex">
              <label
                v-if="!$vuetify.breakpoint.xsOnly"
                class="pt-4 pr-2"
              >
                Sort by:
              </label>
              <AdsSelect
                v-model="resourceSort"
                label=""
                style="width:200px"
                aria-label="Sort by"
                item-text="text"
                item-value="value"
                :items="sortOptions"
                outlined
                hide-details
              />
              <ToggleButtonGroup
                v-if="!$vuetify.breakpoint.xsOnly"
                :leftClickHandler="setGridView"
                :rightClickHandler="setListView"
              />
            </div>
          </v-col>
        </v-row>
        <!-- resource cards in grid view -->
        <ResourceGrid
          v-if="gridView"
          :items="searchResultsSorted"
          :selectable="enableResourceSelections"
          :selected-cards="selectedCards"
          :sequence="sequence"
          :loading="loading"
          :show-sequence="learningSequenceSet"
          @onSelect="onCardSelectHandler"
        />
        <!-- resource cards in list view -->
        <ResourceList
          v-else
          :items="searchResultsSorted"
          :selectable="enableResourceSelections"
          :selectedCards="selectedCards"
          :sequence="sequence"
          :loading="loading"
          :show-sequence="learningSequenceSet"
          @onSelect="onCardSelectHandler"
        />
        <!-- </v-container> -->
      </v-col>
      <v-col v-else>
        <LearningSequencePage
          @go:back="handleBack"
          @confirmSequence="sortBySequence"
        />
      </v-col>
      <!-- <DiscussionDrawer
        :members="collection.members"
        class="mt-3"
      /> -->
    </v-row>

    <!-- Mobile view -->
    <template v-else>
      <MobileView />
    </template>
    <!--
        When in $vuetify.smAndUp is true, we need to show either of the following
        if showCollection is true, we will show ResourceGrid and ResourceList
        else we wil show all the discussion for selected collection.
     -->


    <!-- OPTIONS FROM BANNER ACTION MENU -->

    <!-- Edit collection dialog -->
    <EditMetaData
      v-model="showEditMetaDataDialog"
      :collection="$store.state.myCollections.collectionDetails"
      @collections:updated="refreshCollection"
    />

    <InviteMembers
      v-model="inviteMembersDialog"
      :collection="collection"
      @update:collection="fetchCollection"
    />

    <!-- Delete collection dialog -->
    <DeleteCollectionDialog
      v-model="deleteCollection"
      :collectionId="collection.ItemID"
      :collectionName="collection.Name"
      @confirmDeletion="handleDelete"
    />

    <!-- Collection information drawer -->
    <CollectionInformation
      v-model="informationDrawer"
      :collection="collection"
      :rce="editorView"
      @invite="inviteMembersDialog = true"
    />

    <!-- Learning Sequence Dialog -->

    <LearningSequence
      v-model="showLearningSequenceDilaog"
      :collectionId="collection.ItemID"
      :collectionName="collection.Name"
    />

    <LeaveCollection
      v-model="leaveCollection"
      :collectionId="collection.ItemID"
      :collectionName="collection.Name"
      @confirm="handleLeaveCollection"
    />

    <!-- ACTION MENU DIALOGS -->

    <!-- Confirm edit resources and unpublish collection dialog -->
    <ConfirmEditAndUnpublish
      v-model="confirmEditBeforeUnpublish"
      :items="resources"
      :selectedCards="selectedCards"
      :hub-collection="hubCollection"
      @confirm="onConfirmEditNUnpubish"
    />

    <!-- Remove from collection dialog -->
    <RemoveResources
      v-model="showRemoveFromCollectionDialog"
      :items="resources"
      :selectedCards="selectedCards"
      @onSelect="onCardSelectHandler"
      @onComplete="confirmEdit"
      @cancel="cancelEdit"
    />

    <!-- Copy resources to collection dialog -->
    <CopyToCollection
      v-model="showCopyCollectionDialog"
      :items="resources"
      :selectedCards="selectedCards"
      :hub-collection="hubCollection"
      @onSelect="onCardSelectHandler"
      @onComplete="confirmEdit"
    />

    <!-- Move resources to collection dialog -->
    <MoveToCollection
      v-model="showMoveToCollectionDialog"
      :items="resources"
      :selectedCards="selectedCards"
      :hub-collection="hubCollection"
      @onSelect="onCardSelectHandler"
      @onComplete="confirmEdit"
      @cancel="cancelEdit"
    />

    <SubmitForReviewDialog
      v-model="submitForReviewDialog"
      hub-collection
      :application="collection.application"
      :source="collection.source"
      :title="collection.Name"
      :record-id="collection.record.id"
      @onSubmit="refreshCollection"
    />

    <EndorseDialog
      v-model="showEndorseDialog"
      :application="collection.application"
      :source="collection.source"
      hub-collection
      :title="collection.Name"
      :record-id="collection.record.id"
      @onSubmit="refreshCollection"
    />

    <DeclineDialog
      v-model="showDeclineDialog"
      :application="collection.application"
      :source="collection.source"
      hub-collection
      :title="collection.Name"
      :record-id="collection.record.id"
      @onSubmit="refreshCollection"
    />
    <ShareListDialog
      v-if="shareCollection"
      v-model="shareCollection"
      title="Share this collection"
      :item="shareItem"
    />
  </div>
</template>

<script>
import Banner from '@/components/Banner'
// import DiscussionDrawer from '@/views/CollectionBoardPage/DiscussionDrawer/DiscussionDrawer.vue'
import {AdsButton, AdsSelect, AdsTextField, ToggleButtonGroup, ADS_Colors, Chip} from '@nswdoe/doe-ui-core'
import {
  EDPUSH_ACTION,
  COLLECTION_SORT_OPTIONS,
  ResourceStatus,
  COLLECTION_RESOURCE_ACTIONS,
} from '@/constants';
import ActionsMenu from '@/views/ResourceEditorDetailsPage/ActionsMenu/ActionsMenu'
import ResourceList from '@/components/Resource/ResourceList'
import ResourceGrid from '@/components/Resource/ResourceGrid'
import CollectionInformation from '@/views/CollectionBoardPage/InformationDrawer/CollectionInformation.vue';
import MobileView from '@/views/CollectionBoardPage/MobileView/MobileView.vue'
import CircleActionMenu from '@/views/CollectionBoardPage/CircleActionMenu/CircleActionMenu.vue';
import LearningSequencePage from '@/components/Collection/LearningSequence/LearningSequence.vue'
import SubmitForReviewDialog from '@/components/Dialogs/Editor/SubmitForReviewDialog';
import EndorseDialog from '@/components/Dialogs/Editor/EndorseDialog';
import DeclineDialog from '@/components/Dialogs/Editor/DeclineDialog';
import ShareListDialog from '@/components/Dialogs/Editor/ShareListDialog';

import {
    InviteMembers,
    DeleteCollectionDialog,
    CopyToCollection,
    MoveToCollection,
    EditMetaData,
    RemoveResources,
    LeaveCollection,
    LearningSequence,
    ConfirmEditAndUnpublish
  } from '@/components/Dialogs/Collections';
import {
  SubmitForReviewButton,
  EndorseButton,
  DeclineButton,
  PublishButton,
  UnpublishButton
} from './WorkflowButtons'
import {mapGetters} from 'vuex';
import statusChipMixin from '@/mixins/statusChipMixin';

export default {
  name: 'CollectionBoardPage',
  components: {
    ConfirmEditAndUnpublish,
    UnpublishButton,
    Chip,
    PublishButton,
    EndorseButton,
    CopyToCollection,
    MoveToCollection,
    ResourceGrid,
    ActionsMenu,
    AdsButton,
    AdsSelect,
    AdsTextField,
    EditMetaData,
    RemoveResources,
    Banner,
    ResourceList,
    ToggleButtonGroup,
    InviteMembers,
    DeleteCollectionDialog,
    CollectionInformation,
    MobileView,
    LearningSequence,
    LearningSequencePage,
    LeaveCollection,
    CircleActionMenu,
    DeclineButton,
    SubmitForReviewButton,
    SubmitForReviewDialog,
    EndorseDialog,
    DeclineDialog,
    ShareListDialog
  },
  mixins: [statusChipMixin],
  data() {
    return {
      ADS_Colors,
      editMode: false,
      gridView: true,
      loading: false,
      confirmEditAction: '',
      confirmEditBeforeUnpublish: false,
      showSequence: false,
      showEditMetaDataDialog: false,
      showRemoveFromCollectionDialog: false,
      showCopyCollectionDialog: false,
      showMoveToCollectionDialog: false,
      showLearningSequenceDilaog: false,
      isCollection: true,
      isDiscussion: false,
      cardSize: 320,
      searchString: '',
      selectedCards: [],
      inviteMembersDialog: false,
      deleteCollection: false,
      informationDrawer: false,
      leaveCollection: false,
      shareCollection: false,
      sequence: [],
      editModeButton: {
        'text': 'Actions',
        'icon': 'mdi-dots-vertical',
        'small': this.$vuetify.breakpoint.mdAndDown,
        'secondary': false,
        'tertiary': false
      },
      moreActionsButton: {
        'text': 'Options',
        'color': '#2196f3',
        'icon': 'arrow_drop_down',
        'small': this.$vuetify.breakpoint.mdAndDown,
        'secondary': true,
        'tertiary': false
      },
      sortOptions: [
        {
          text: 'Date added: Newest to oldest',
          value: COLLECTION_SORT_OPTIONS.ADDED_ASC,
        }, {
          text: 'Date added: Oldest to newest',
          value: COLLECTION_SORT_OPTIONS.ADDED_DESC
        }, {
          text: 'Last updated: Newest to oldest',
          value: COLLECTION_SORT_OPTIONS.UPDATED_ASC
        }, {
          text: 'Last updated: Oldest to newest',
          value: COLLECTION_SORT_OPTIONS.UPDATED_DESC
        }, {
          text: 'Sequence order',
          value: COLLECTION_SORT_OPTIONS.SEQUENCE
        }],
      ownerEditActions: [
        {
          id: 'invite',
          name: 'Invite Members',
          tag: 'button',
          icon: 'group_add',
        },
        {
          id: 'edit',
          name: 'Edit details',
          tag: 'button',
          icon: 'mdi-pencil-outline'
        },
        {
          id: 'copy',
          name: 'Make a copy',
          tag: 'button',
          icon: 'mdi-content-copy',
        },
        {
          id: 'learningSequence',
          name: 'Create Learning Sequence',
          tag: 'button',
          icon: 'format_list_numbered',
        },
        {
          id: 'info',
          name: 'Information',
          tag: 'button',
          icon: 'info_outline',
        },
        {
          id: 'delete',
          name: 'Delete',
          tag: 'button',
          icon: 'delete',
        }
      ],
      editorEditActions: [
        {
          id: 'invite',
          name: 'Invite Members',
          tag: 'button',
          icon: 'group_add',
        },
        {
          id: 'edit',
          name: 'Edit details',
          tag: 'button',
          icon: 'mdi-pencil-outline'
        },
        {
          id: 'copy',
          name: 'Make a copy',
          tag: 'button',
          icon: 'mdi-content-copy',
        },
        {
          id: 'learningSequence',
          name: 'Create Learning Sequence',
          tag: 'button',
          icon: 'format_list_numbered',
        },
        {
          id: 'info',
          name: 'Information',
          tag: 'button',
          icon: 'info_outline',
        },
        {
          id: 'leave',
          name: 'Leave Collection',
          tag: 'button',
          icon: 'info_outline',
        }
      ],
      viewerEditActions: [
        {
          id: 'invite',
          name: 'Invite Members',
          tag: 'button',
          icon: 'group_add',
        },
        {
          id: 'copy',
          name: 'Make a copy',
          tag: 'button',
          icon: 'mdi-content-copy',
        },
        {
          id: 'info',
          name: 'Information',
          tag: 'button',
          icon: 'info_outline',
        },
        {
          id: 'leave',
          name: 'Leave Collection',
          tag: 'button',
          icon: 'info_outline',
        }
      ],
      hubCollectionViewerActions: [
        {
          id: 'share',
          name: 'Share this collection',
          tag: 'button',
          icon: 'share',
        },
        {
          id: 'copy',
          name: 'Make a copy',
          tag: 'button',
          icon: 'mdi-content-copy',
        },
        {
          id: 'info',
          name: 'Overview',
          tag: 'button',
          icon: 'info_outline',
        }
      ],
      viewerActions: [
        {
          id: COLLECTION_RESOURCE_ACTIONS.COPY,
          name: 'Copy to another collection',
          tag: 'button',
          icon: 'mdi-content-copy',
        },
      ],
      editorActions: [
        {
          id: COLLECTION_RESOURCE_ACTIONS.MOVE,
          name: 'Move to another collection',
          tag: 'button',
          icon: 'input',
        },
        {
          id: COLLECTION_RESOURCE_ACTIONS.DELETE,
          name: 'Remove from collection',
          tag: 'button',
          icon: 'remove_circle_outline',
        }
      ],
      submitForReviewDialog: false,
      showEndorseDialog: false,
      showDeclineDialog: false
    }
  },
  computed: {
    ...mapGetters({
      hasPublisherAccessTo: 'users/hasPublisherAccessTo',
      hasReviewerAccessTo: 'users/hasReviewerAccessTo',
      hasAuthorAccessTo: 'users/hasAuthorAccessTo',
      hasViewerAccessTo: 'users/hasViewerAccessTo',
      // isPublisher: 'users/isPublisher',
      // isAuthor: 'users/isAuthor',
      // isReviewer: 'users/isReviewer',
      // isViewer: 'users/isViewer',
      allStages: 'metadata/stages'
    }),
    isPublisher() {
      return this.checkAccess(this.hasPublisherAccessTo, this.collection.source)
    },
    isReviewer() {
      return this.checkAccess(this.hasReviewerAccessTo, this.collection.source)
    },
    isAuthor() {
      return this.checkAccess(this.hasAuthorAccessTo, this.collection.source)
    },
    isViewer() {
      return this.checkAccess(this.hasViewerAccessTo, this.collection.source)
    },
    stages(){
      return [ ...this.collection?.Stages || [] ].sort().map(stage => {
        if (stage.startsWith('ES')) {
          return stage.replace('ES', 'Early Stage ')
        }
        if (stage === 'Preschool') {
          return stage
        }
        return `Stage ${stage}`
      }) || []
    },
    editorView() {
      return this.$route.query?.rce === 'true' || this.$route.query?.rce === true || false
    },
    showEditorView() {
      return this.editorView && !this.isViewer
    },
    hasEditCollectionAccess() {
      return !this.isViewer && (this.isDraft || this.isDeclined || this.isPublisher || (this.isReviewer && !this.isPublished)) || (this.isAuthor && this.isPending)
    },
    hubCollectionEditActions() {
      return  [
        {
          id: 'edit',
          name: 'Edit details',
          tag: 'button',
          icon: 'mdi-pencil-outline',
          disable: !this.hasEditCollectionAccess
        },
        {
          id: 'learningSequence',
          name: 'Create Learning Sequence',
          tag: 'button',
          icon: 'format_list_numbered',
          disable: !this.hasEditCollectionAccess
        },
        {
          id: 'info',
          name: 'Information',
          tag: 'button',
          icon: 'info_outline',
        },
        {
          id: 'delete',
          name: 'Delete',
          tag: 'button',
          icon: 'delete',
          disable: !((this.isDraft || this.isDeclined) && (this.collection.isOwner || this.isPublisher))
        }
      ]
    },
    shareItem() {
      return {
        id: this.collection.ItemID,
        name: this.collection.Name,
        url: window.location.href
      }
    },
    enableResourceSelections() {
      return this.myCollection || (this.showEditorView && this.collectionResourceActions.length > 0)
    },
    inviteMembers:{
      get(){
        return this.$store.getters['myCollections/inviteMembers']
      },
      set(value){
        this.$store.commit('myCollections/SHOW_INVITE_MEMBERS', value)
      }
    },
    collectionResourceActions() { // Actions menu
      if (this.myCollection) {
        return this.collection.isOwner ? [...this.viewerActions, ...this.editorActions] :this.collection.isEditor ? this.viewerActions : this.editorActions
      }
      if (this.isPublished) {
        return this.isPublisher ? [...this.viewerActions, ...this.editorActions] : [];
      }
      if (this.isEndorsed) {
        return this.isPublisher || this.isReviewer ? [...this.viewerActions, ...this.editorActions] : []
      }
      // for draft/pending/declined status allow all actions
      return [...this.viewerActions, ...this.editorActions]
    },
    editCollectionActions() { // Options Menu
      return this.hubCollection ? this.hubCollectionActions : this.myCollectionActions
    },
    hubCollectionActions() {
      return this.showEditorView ? this.hubCollectionEditActions: this.hubCollectionViewerActions
    },
    myCollectionActions() {
      return this.collection.isOwner?this.ownerEditActions:this.collection.isEditor?this.editorEditActions:this.viewerEditActions
    },
    userId() {
      return this.$store.getters['users/userProfile']?.userId?.toLowerCase() || '';
    },
    myCollection() {
      return this.collection.source === 'collections'
    },
    hubCollection() {
      return this.collection.source !== 'collections'
    },
    collection() {
      const collection = this.$store.state.myCollections.collectionDetails
      const isOwner = collection.userAccess.owner.includes(this.userId)
      const isEditor = collection.userAccess.editor.includes(this.userId)
      const status = collection.record.status
      const source = collection.record.collection
      return {
          ...(collection.record.status !== ResourceStatus.published && collection.draft),
          ...(collection.record.status === ResourceStatus.published && collection.document),
          ...collection.document,
          isOwner,
          isEditor,
          status,
          statusLabel: this.getKeyByValue(status),
          source,
          application: collection.audit.iss,
          files: collection.files,
          isPending: status === ResourceStatus.pending,
          isDraft: status === ResourceStatus.draft,
          isEndorsed: status === ResourceStatus.endorsed,
          hubCollection: source !== 'collections',
          userAccess: collection.userAccess,
          resources: collection.subResources,
          accessGroup: isOwner? 'Created By Me' : isEditor? 'Shared By Me': 'Shared With Me',
          followers: collection.userAccess.followers,
          members: [ ...collection.userAccess.owner, ...collection.userAccess.editor, ...collection.userAccess.viewer],
          record: collection.record,
          audit: collection.audit
        }
    },
    learningSequenceSet () {
      return !!this.collection.record.isLearningSequence
    },
    isDraft () {
      return this.collection.isDraft
    },
    isPending () {
      return this.collection.isPending
    },
    isDeclined () {
      return this.collection.status === ResourceStatus.declined
    },
    isEndorsed() {
      return this.collection.status === ResourceStatus.endorsed
    },
    isPublished() {
      return this.collection.status === ResourceStatus.published
    },
    isFollowing() {
      return this.collection.followers?.includes(this.userId) || false
    },
    subResources () {
      return this.$store.state.myCollections.subResources
    },
    resources() {
      const subs = this.collection.resources?.map((item, index ) => {
        const resourceDetails = this.getSubResource(item.resourceId.id)
        console.log('subresource', resourceDetails)
        // if (resourceDetails) { @TODO display the unpublished resources and flag them
        //   this.sequence.push(resourceDetails?.record?.id)
        return resourceDetails && {
          ...resourceDetails,
          resourceId: item.resourceId,
          isResourceCollection: resourceDetails?.record?.isResourceCollection,
          ThumbnailImage: item.image,
          DateAdded: item.dateAdded,
          ...resourceDetails?.document,
          file: resourceDetails?.files?.[0]
        }
        // } else {
        //   return {
        //     sequence: index,
        //     resourceId: item.resourceId,
        //     ThumbnailImage: item.image,
        //     DateAdded: item.dateAdded
        //   }
        // }
      })
      return subs.filter(Boolean)
    },
    resourceSort: {
      get() {
        return this.$store.state.myCollections.resourceSorting;
      },
      set(val) {
        this.$store.commit('myCollections/SET_RESOURCE_SORTING', val)
      }
    },
    searchResults() {
      if (this.searchString ==='' || !this.searchString || this.searchString.length < 3 ) {
        return this.resources
      } else {
        return this.resources?.filter(resource => resource.Name.toLowerCase().includes(this.searchString.toLowerCase()) || resource.Description.toLowerCase().includes(this.searchString.toLowerCase()))
      }
    },
    searchResultsSorted() {
      let results = [ ...this.searchResults]

      switch (this.resourceSort) {
        case COLLECTION_SORT_OPTIONS.ADDED_ASC:
          results = results.sort((a, b) => {
            return new Date(a.DateAdded) < new Date(b.DateAdded) ? 1 : -1
          })
          break;
        case COLLECTION_SORT_OPTIONS.ADDED_DESC:
          results = results.sort((a, b) => {
            return new Date(a.DateAdded) < new Date(b.DateAdded) ? -1 : 1
          })
          break;
        case COLLECTION_SORT_OPTIONS.UPDATED_ASC:
          results = results.sort((a, b) => {
            return new Date(a.DateModified) < new Date(b.DateModified) ? 1 : -1
          })
          break;
        case COLLECTION_SORT_OPTIONS.UPDATED_DESC:
          results = results.sort((a, b) => {
            return new Date(a.DateModified) < new Date(b.DateModified) ? -1 : 1
          })
          break;
        case COLLECTION_SORT_OPTIONS.SEQUENCE:
          break;
        default:
          results = results.sort((a, b) => {
            return new Date(a.DateAdded) < new Date(b.DateAdded) ? -1 : 1
          })
          break;
      }
      this.$store.commit('myCollections/SET_SORTED_SUB_RESOURCES', results);
      return results
    },
    banner() {
      return {
        heading: this.collection.Name || this.collection.document?.Name,
        subtitle: this.collection.Description || this.collection.document?.Description,
        icon: 'bookmarks',
        iconSize: '3.5rem'
      }
    },
  },
  watch: {
    userId: {
      immediate: true,
      async handler() {
        await this.$store.dispatch('myCollections/fetchMyEditableCollections');
      }
    },
    resourceSort: {
      immediate: true,
      handler(val) {
        if (val === COLLECTION_SORT_OPTIONS.SEQUENCE) {
          this.sequence = this.resources.map(r => r.record.id)
        }
      }
    },
  },
  async beforeRouteUpdate(to, from, next) {
    if (to.name === from.name && to.params.id !== from.params.id) {
      const collectionDetails = await this.$store.dispatch('myCollections/fetchCollection', {
        ...to.params,
        hubCollection: to.query.rce,
        rce: to.query.rce
      });
      this.getActivityLogs(to.params.id)
      if (!collectionDetails) {
        this.$store.commit('errors/SET_ERROR_TYPE', 'collectionNotFound');
        next('/notfound');
        return;
      }
    }
    next();
  },
  methods: {
    handleBack(){
      this.showSequence = false;
    },
    checkAccess(accesses, contentArea) {
      return accesses.findIndex(ca => ca.id === contentArea) > -1
    },
    refreshCollection(a) {
      this.fetchCollection()
      this.getActivityLogs(this.collection.record.id)
    },
    getKeyByValue(value) {
      const statuses = ResourceStatus
      return Object.keys(statuses).find(key => statuses[key] === value)
    },
    async handlePublish() {
      const isPublished = this.collection.status === ResourceStatus.published;
      // step 1 update MongoDB
      const payload = {
        isPublished,
        //scope: this.contentAdmin.scope,
        application: this.collection?.application,
        owner: this.collection?.userAccess.owner,
        // if record.published = true, use the document object; if false, use the draft object
        document: this.isPublished ? this.$store.state.myCollections.collectionDetails.document : this.$store.state.myCollections.collectionDetails.draft,
        displayAlert: true
      };
      const response = await this.$store.dispatch('myCollections/publishOrUnPublishCollection', payload);
      if (response) {
        this.refreshCollection()
        console.log('publishing collection ... elastic search update')
      }
    },
    sortBySequence() {
      this.$store.commit('myCollections/SET_RESOURCE_SORTING', COLLECTION_SORT_OPTIONS.SEQUENCE)
      this.sequence = this.resources.map(r => r.record.id)
      this.getActivityLogs(this.collection.record.id)
    },
    async getActivityLogs(id) {
      await this.$store.dispatch('myCollections/getCollectionNotification', {
        collectionId: id,
        viewmode: this.editorView ? 'rceactivity': 'urhcolactivity'
      })
    },
    getSubResource(id) {
      return this.subResources.find(resource => resource.document.ItemID === id)
    },
    async fetchCollection() {
      await this.$store.dispatch('myCollections/fetchCollection', {
        id: this.collection.ItemID,
        hubCollection: this.hubCollection,
        application: this.collection?.application,
        source: this.collection?.record.collection,
        rce: this.editorView
      });
    },
    async unFollowCollection() {
      this.loading = true;
      await this.$store.dispatch('myCollections/followCollection', {
        collection: this.$store.state.myCollections.collectionDetails,
        follow: false
      });
      this.loading = false;
    },
    async followCollection() {
      this.loading = true;
      await this.$store.dispatch('myCollections/followCollection', {
        collection: this.$store.state.myCollections.collectionDetails,
        follow: true
      });
      this.loading = false;
    },
    // shows confirm dialog for editing sub resources
    onConfirmEditNUnpubish() {
      if (this.confirmEditAction === COLLECTION_RESOURCE_ACTIONS.MOVE) {
        this.showMoveToCollectionDialog = true;
      } else {
        this.showRemoveFromCollectionDialog = true;
      }
    },
    // after editing resources
    async confirmEdit() {
      this.loading = true
      this.clearSelection()
      if ([ COLLECTION_RESOURCE_ACTIONS.MOVE, COLLECTION_RESOURCE_ACTIONS.DELETE ].includes(this.confirmEditAction)) {
        // unpublish
        await this.handlePublish()
        this.confirmEditAction = ''
      }
      this.getActivityLogs(this.collection.record.id)
      this.loading = false
    },
    cancelEdit () {
      this.confirmEditAction = ''
      this.loading = false
    },
    clearSelection() {
      this.selectedCards = []
    },
    handleMoreActionsMenu(action) {
      switch (action) {
        case 'duplicate':
          console.log('duplicate action...')
          break;
        case 'edit':
          this.showEditMetaDataDialog = true
          break;
        case 'invite':
          this.inviteMembersDialog = true
          break;
        case 'delete':
          this.deleteCollection = true
          break;
        case 'learningSequence':
          //this.showSequence = true;
          if(this.$vuetify.breakpoint.smAndUp){
            this.showSequence = true;
          }else{
            this.showLearningSequenceDilaog = true;
          }
          break;
        case 'learningSequence2':
          this.showLearningSequenceDilaog = true;
          break;
        case 'info':
          this.informationDrawer = true
          break;
        case 'copy':
          this.handleCopyCollection()
          break;
        case 'leave':
          this.leaveCollection = true;
          break;
        case 'share':
          this.shareCollection = true;
          break;
      }
    },
    handleCollectionActionsMenu(action) {
      this.confirmEditAction = ''
      switch (action) {
        case COLLECTION_RESOURCE_ACTIONS.COPY:
          this.showCopyCollectionDialog = true
          break;
        case COLLECTION_RESOURCE_ACTIONS.MOVE:
          console.log('moving to another', this.resources.length -this.selectedCards.length, (this.resources.length - this.selectedCards.length) < 2)
          // if this action sets no of resources to be less than 2, get it confirmed before un-publishing the collection
          if ((this.resources.length - this.selectedCards.length) < 2 && this.isPublished) {
            this.confirmEditBeforeUnpublish = true
            this.confirmEditAction = COLLECTION_RESOURCE_ACTIONS.MOVE
          } else {
            this.showMoveToCollectionDialog = true
          }
          break;
        case COLLECTION_RESOURCE_ACTIONS.DELETE:
          console.log('deleting sub resources', this.resources.length -this.selectedCards.length, (this.resources.length - this.selectedCards.length) < 2)
          // if this action sets no of resources to be less than 2, get it confirmed before un-publishing the collection
          if ((this.resources.length - this.selectedCards.length) < 2 && this.isPublished) {
            this.confirmEditBeforeUnpublish = true
            this.confirmEditAction = COLLECTION_RESOURCE_ACTIONS.DELETE
          } else {
            this.showRemoveFromCollectionDialog = true
          }
          break;
        case 'info':
          this.informationDrawer = true
      }
    },
    setGridView() {
      this.gridView = true
    },
    setListView() {
      this.gridView = false
    },
    onCardSelectHandler(val) {
      /**
       * val: {
       *   select: type Boolean, // selected or not
       *   item: type Object<ResourceItem>
       * }
       *
       * **/
      if(val.select) {
        // add object to array
        this.selectedCards.push(val.item)
      } else {
        //delete from list
        this.selectedCards = this.selectedCards.filter(card => card.resourceId.id !== val.item.resourceId.id)
      }
    },
    /**
     * When updating an existing resource, e.g. update a created draft/pending resource
     * @returns {Promise<void>}
     */
    async handleUpdate(action = EDPUSH_ACTION.SAVE_AS_DRAFT, assigneeId, status) {
      this.loading = true;

      console.log('assignee update collections')

      this.loading = false;
    },
    async handleDelete() {
      if (this.collection) {
        let payload = {
          collectionId: this.collection.ItemID,
          source: this.collection.source,
          application: this.collection.application,
          displayAlert: true,
          subResources: this.collection.resources
        }
        const response = await this.$store.dispatch('myCollections/deleteCollection', payload);
        if (response) {
          // go to collection homepage || editor home
          this.$router.push({name: this.hubCollection? 'ResourceEditorPage': 'MyCollection'});
        }
      }
    },
    async handleLeaveCollection(){
      const action = 'removeaccess'
      const userId = this.$store.state.uid
      const alert= {
        type: 'success',
        text: 'Your preference is successfully updated'
      }

      const isEditor = this.collection.userAccess.editor.includes(userId)
      const scope = isEditor ? 'editor' : 'viewer'

      let payload = {
        action,
        alert,
        collection: this.collection.document ? this.collection.document.ItemID : this.collection.ItemID,
        userAccess:{
          accessFor: userId,
          accessScope: scope
        }
      };
      const response = await this.$store.dispatch('myCollections/shareCollection', payload)
      if(response) {
        // go to collection homepage
        this.$router.push({name: 'MyCollection'});
      }
    },
    async handleCopyCollection() {
      const copiedCollection = await this.$store.dispatch('myCollections/copyCollection', {action: 'copy', collection: this.$store.state.myCollections.collectionDetails});

      if (copiedCollection && copiedCollection.document) {
        // after creating the resource, replace the local data with the data returned from the api
        this.document = copiedCollection.document || copiedCollection.draft;

        // update the route with the id and turn edit mode off
        const modifiedRoute = Object.assign({}, this.$route);

        modifiedRoute.params.id = copiedCollection.record.id;
        modifiedRoute.query = {};
        this.$router.replace(modifiedRoute).catch(() => {});
        this.editMode = true;
        this.showEditMetaDataDialog = true
      }
    },
    showCollection(event){
      console.log('show collection...');
      if(event.target.innerText === 'COLLECTION'){
        this.isCollection = true;
        this.isDiscussion = false;
      }
    },
    showDiscussion(event){
      console.log('show discussion...');
      if(event.target.innerText === 'DISCUSSION'){
        this.isCollection = false;
        this.isDiscussion = true;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variables";

::v-deep .collection-metadata {
  .right-panel {
    min-width: 30%;
  }
}
.collection-metadata {
  .status-chip {
    max-width: 250px;
  }
}
.v-tab.page--tab{
  flex: 1;
}

// need to remove flex property so that resource cards can be fixed accordingly.
::v-deep .cards_container {
  flex: 0;
}
</style>
