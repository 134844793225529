<template>
  <v-infinite-scroll
    :loading="loading"
    :offset="20"
    max-height="99999999"
    class="mb-15 hub-scroll-bar"
    @bottom="loadMoreHubResources"
  >
    <v-row
      v-if="gridView || $vuetify.breakpoint.xsOnly"
      :class="{'ma-0': $vuetify.breakpoint.xsOnly}"
      align="center"
      justify="start"
    >
      <v-col
        v-for="(item, index) in resources"
        :key="index"
        cols="auto"
        :class="{'px-0': $vuetify.breakpoint.width < 620}"
      >
        <v-responsive
          v-if="$store.state.isLoading && !$store.state.userScroll"
          :min-width="cardSize"
          :min-height="cardSize"
        >
          <v-skeleton-loader
            boilerplate
            type="image, list-item-three-line, actions"
          />
        </v-responsive>
        <ResourceCollectionsCard
          v-if="isCollection(item)"
          :item="item"
          :size="cardSize"
          :height="380"
        />
        <ResourceCard
          v-else
          :num="index"
          :item="item"
          :size="cardSize"
          :height="380"
          :rounded="$vuetify.breakpoint.width >= 620"
        />
      </v-col>
    </v-row>

    <v-container v-if="!gridView && $vuetify.breakpoint.smAndUp">
      <div
        v-for="(item, index) in resources"
        :key="index"
      >
        <ResourceCardList :item="item" />
      </div>
    </v-container>

  </v-infinite-scroll>
</template>

<script>
import ResourceCard from '@/views/HubResourcesPage/ResourceCard';
import ResourceCardList from '@/views/HubResourcesPage/ResourceCardList';
import {SEARCH_CONTEXT} from '@/constants';
import {mapGetters} from 'vuex';
import ResourceCollectionsCard from '@/components/Collection/CollectionCard';

export default {
  name: 'SearchResultsContent',
  components: {
    ResourceCollectionsCard,
    ResourceCard,
    ResourceCardList
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    resources: {
      type: Array,
      default: () => []
    },
    gridView: {
      type: Boolean,
      default: true
    },
    cardSize: {
      type: Number,
      default: 350
    },
    isMyResources: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      infiniteScrollBusy: false,
      viewportHeight: null,
    }
  },
  watch: {
    hitBottomCount() {
      this.loadMoreHubResources()
    }
  },
  computed: {
    ...mapGetters({
      hitBottomCount: 'hitBottomCount'
    }),
    searchContextIsHub() {
      return this.$store.state.searchContext === SEARCH_CONTEXT.HUB;
    },
  },
  methods: {
    isCollection(r) {
      return r?.record?.isResourceCollection;
    },
    loadMoreOnResize() {
      if (!this.viewportHeight) {
        this.viewportHeight = window.innerHeight;
      } else {
        if (this.viewportHeight < window.innerHeight) {
          this.loadMore();
          this.viewportHeight = window.innerHeight;
        }
      }
    },
    loadMore() {
      if (this.searchContextIsHub) {
        this.loadMoreMyResources();
      } else {
        this.loadMoreHubResources()
      }
    },
    async loadMoreMyResources() {
      console.log('triggered loadmore myr')
      const myResources = this.$store.state.myResources;
      this.infiniteScrollBusy = true;
      if (myResources.pageNum < myResources.searchResults.metadata.totalPages) {
        await this.$store.commit('myResources/SET_USER_SCROLL');
        await this.$store.commit('myResources/INCREASE_PAGE_NUM');
        await this.$store.dispatch('myResources/fetchResources');
      }
      this.infiniteScrollBusy = false;
    },
    async loadMoreHubResources() {
      // console.log('triggered loadmore hub', this.$route.name)
      if(this.$route.name !== 'HubResourcesPage') {
        return false
      }
      const searchResults = this.$store.state.resources;
      if (searchResults.items.length < 8 ||
        this.$store.state.pageNum >= searchResults.metadata.totalPages ||
        !this.searchContextIsHub) {
        return false;
      }
      this.infiniteScrollBusy = true;
      this.$store.commit('SET_USER_SCROLL');
      this.$store.commit('SET_PAGE_NUM', this.$store.state.pageNum + 1);
      await this.$store.dispatch('fetchResourcesWithClient');
      this.infiniteScrollBusy = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/scss/variables";
@import "src/scss/mixins";

.v-skeleton-loader {
  height: 100%;

  ::v-deep.v-skeleton-loader__image {
    height: 50% !important;
  }
}

.hub-scroll-bar {
  //max-height: 80vh;
  overflow-x: hidden;

  & {
    scrollbar-color: $ads-light-40 !important;
  }

  /* Works on Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 0px;
  }

  &::-webkit-scrollbar-track {
    background: $ads-white;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $ads-light-40;
    border-radius: 20px;
  }
}
</style>
