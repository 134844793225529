<template>
  <div
    v-if="items"
    class="ml-4 mt-5"
  >
    <v-row
      :class="{'ma-0': $vuetify.breakpoint.xsOnly}"
      align="center"
      justify="start"
    >
      <v-col
        v-for="(item, index) in items"
        :key="index"
        class="cards_container"
        :sm="$vuetify.breakpoint.width < 1700 ? '4': '3'"
        :class="{'px-0': $vuetify.breakpoint.width < 620}"
      >
        <v-responsive
          v-if="loading"
          :min-width="cardSize"
          :min-height="cardSize"
        >
          <v-skeleton-loader
            boilerplate
            type="image, list-item-three-line, actions"
          />
        </v-responsive>
        <template v-else>
          <ResourceCollectionsCard
            v-if="item.isResourceCollection"
            v-bind="$attrs"
            :item="item"
            :size="cardSize"
            :enable-selection="selectable"
            :isSelected="isSelected(item.resourceId.id)"
            :height="400"
            :sequence-order="showSequence"
            :item-index="getSequenceNumber(item.resourceId.id)"
            :item-length="items.length"
            v-on="$listeners"
          />
          <ResourceCard
            v-else
            v-bind="$attrs"
            :num="index"
            :item="item"
            :enable-selection="selectable"
            :isSelected="isSelected(item.resourceId.id)"
            :sequence-order="showSequence"
            :item-index="getSequenceNumber(item.resourceId.id)"
            :item-length="items.length"
            :rounded="$vuetify.breakpoint.width >= 620"
            v-on="$listeners"
          />
        </template>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ResourceCard from '@/components/Resource/ResourceCard'
import ResourceCollectionsCard from '@/components/Collection/CollectionCard';

export default {
  name: 'CollectionBoardPage',
  components: {
    ResourceCollectionsCard,
    ResourceCard
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    selectedCards: {
      type: Array,
      default: () => []
    },
    selectable: {
      type: Boolean,
      default: false
    },
    showSequence: {
      type: Boolean,
      default: false
    },
    sequence: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      cardSize: 350
    }
  },
  methods: {
    isSelected(id) {
      return !!this.selectedCards.find(item => item.resourceId.id === id)
    },
    getSequenceNumber(id) {
      return this.sequence?.findIndex((element) => element === id)
    }
  }
}
</script>

