import api from '@/api';
import {metadataBaseUrl} from '@/store/config';
import {OUTCOME_SEARCH_URL, URLS} from '@/constants'
import {getStatusesMetadata} from '@/api/modules/metadata';

const moduleMetadata = {
  namespaced: true,
  state: {
    filtersMetadata: { // the order is the same as the response of /metadata-service/filters
      klas: [],
      library: [], // NOT used
      urhlibrary: [], // r4.4
      randntopics: [],
      sources: [],
      type: [],   // this is the Type filter on the UI in R3, in R4 this filter is renamed to be Format, its getter is hubFormat)
      years: [],  // NOT used anymore
      stagesandyears: [], // used for the Stage filter
      stages: [], // NOT used anymore
      format: [], // also use for Hub in R4
      myresourcetype: [],
      myresourcetypelibraries: [],
      mycollectionlibraries: [],
      resourcecategories: [], // SIR + TLR, for search scope
      editorRoles: [],
      resourcetype: [],
      focusarea: [],
      sefalignment: [],
      phase: [],
      editor_roles: [],
      notifications: []
    },
    statusesMetadata: null,
    autosaveTimer: 1200,
    nSyllabus: [],
    syllabuses: [],
    progressions: [],
    progressionsV3: [],
    outcomes: [],
    resourceImages: [],
    schoolTypes: [],
    sipTerms: [],
    total: 0,
    page: 1,
    perPage: 6,
  },
  getters: {
    nSyllabus: state => state.nSyllabus,
    syllabuses: state => state.syllabuses,
    outcomes: state => state.outcomes, // remove this when we have matching outcome id attribute name
    editorOutcomes: state => state.outcomes.map(outcome => {
      return {
        syllabus: outcome.syllabus,
        url: OUTCOME_SEARCH_URL + outcome.outcomecode,
        name: outcome.description,
        id: outcome.outcomecode,
      };
    }),
    progressions: state => state.progressions,
    progressionsV3: state => state.progressionsV3,
    autosaveTimer: state => state.autosaveTimer,
    // below are getters for filtersMetadata:
    resourcecategories: state => state.filtersMetadata.resourcecategories,
    editorRoles: state => state.filtersMetadata.editor_roles,
    klas: state => state.filtersMetadata.klas,
    randntopics: state => state.filtersMetadata.randntopics,
    urhlibrary: state => state.filtersMetadata.urhlibrary,
    filterRAndNTopics: state => {
      const topics = state.filtersMetadata.randntopics;
      let numeracyTopics;
      let readingTopics;
      if (topics) {
        numeracyTopics = topics.filter(t => t.type === 'Numeracy');
        readingTopics = topics.filter(t => t.type === 'Literacy');
      }
      return [
        {
          id: 'reading',
          name: 'Literacy',
          children: readingTopics
        },
        {
          id: 'numeracy',
          name: 'Numeracy',
          children: numeracyTopics
        }
      ]
    },
    filterRAndNTopicsForEditor: state => {
      const arr = [];
      state.filtersMetadata.randntopics.forEach(t => arr.push(t.name as never));
      return [...new Set(arr)];
    },
    sources: state => state.filtersMetadata.sources,
    sourcesForHub: state => state.filtersMetadata.sources.filter(s => s.application !== 'equ'),
    hubFormat: state => state.filtersMetadata.type,
    years: state => state.filtersMetadata.years,
    stagesAndYears: state => state.filtersMetadata.stagesandyears,
    mappedStagesAndYears: state => {
      return state.filtersMetadata.stagesandyears.reduce(function (previousValue, currentValue) {
        let children = [];
        if (currentValue.children) {
          children = currentValue.children.map(c => {
            return {
              stage: currentValue.name.replace('Early Stage ', 'ES').replace('Stage ', ''), // parents name as stage Stage X => X
              stageLabel: currentValue.name, // parents name as stage returns Stage X
              year: c.name.replace('Year ', '').replace('Preschool', 'P').replace('Kindergarten', 'K'), // convert Year X => X, Preschool => P, Kindergarten => K
              id: c.id // will be YEAR_X
            }
          });
        }
        return [...previousValue, ...children]
      }, []);
    },
    scopes: state => state.filtersMetadata.scope,
    applications: state => state.filtersMetadata.application,
    stages: state => state.filtersMetadata.stages,
    formats: state => state.filtersMetadata.format,
    myResourcesTypes: state => state.filtersMetadata.myresourcetype,
    myResourcesLibraries: state => state.filtersMetadata.myresourcetypelibraries,
    myCollectionLibraries: state => state.filtersMetadata.mycollectionlibraries,
    schoolImprovementSources: state => state.filtersMetadata.sources ? state.filtersMetadata.sources.filter(i => i.resourcecatid.includes('sir')) : [],
    hubTypes: state => {
      const hubResourceType = state.filtersMetadata.resourcetype;
      let sir, tlr, gen;
      if (hubResourceType) {
        sir = hubResourceType.filter(t => t.resourcecatid.includes('sir'));
        tlr = hubResourceType.filter(t => t.resourcecatid.includes('tlr'));
        gen = hubResourceType.filter(t => t.resourcecatid.includes('gen'));
      }
      return [
        {
          resourcecatid: 'tlr',
          id: 'hubtype-tlr',
          name: 'Teaching and learning resources',
          children: tlr
        },
        {
          resourcecatid: 'sir',
          id: 'hubtype-sir',
          name: 'School improvement resources',
          children: sir
        },
        {
          resourcecatid: 'gen',
          id: 'hubtype-gen',
          name: 'General',
          children: gen
        },
      ];
    },
    allHubResourceTypes: state => state.filtersMetadata.resourcetype,
    focusArea: state => state.filtersMetadata.focusarea,
    sefAlignment: state => state.filtersMetadata.sefalignment,
    sefAlignmentItems: state => {
      return state.filtersMetadata.sefalignment.reduce(function (previousValue, currentValue) {
        const children = currentValue.children.map(s => {
          return {
            domain: currentValue.name,
            id: s.id,
            element: s.name
          }
        });
        return [...previousValue, ...children]
      }, []);
    },
    phaseItems: state => {
      return state.filtersMetadata.phase.reduce(function (previousValue, currentValue) {
        const children = currentValue.children.map(p => {
          return {
            category: currentValue.resourcecatid,
            cycle: currentValue.name,
            phase: p.name,
            id: p.id
          }
        });
        return [...previousValue, ...children]
      }, []);
    },
    focusAreaItems: state => {
      return state.filtersMetadata.focusarea.reduce(function (previousValue, currentValue) {
        const children = currentValue.children.map(f => {
          return {
            channel: currentValue.name,
            focusArea: f.name,
            id: f.id,
            ownerid: currentValue.ownerid
          }
        });
        return [...previousValue, ...children]
      }, []);
    },
    schoolTypes: state => state.filtersMetadata.schooltype,
    sipTerms: state => state.filtersMetadata.sipterms,
    phases: state => state.filtersMetadata.phase,
    HubResourceSources: state => state.filtersMetadata.sources,
    resourceImages: state => state.resourceImages,
    resourceStatusList: state => state.statusesMetadata.map(status => {
      return {text: status.name, value: status.statusId};
    }),
    getSourceNameById: state => id => state.filtersMetadata.sources.find(source => source.id === id)?.name,
    notifications: state => state.filtersMetadata.notification
  },
  mutations: {
    SET_STATUSES_METADATA(state, statusesMetadata) {
      state.statusesMetadata = statusesMetadata;
    },
    SET_FILTERS_METADATA(state, filtersMetadata) {
      state.filtersMetadata = filtersMetadata; // fetched from the metadata-service/filters api in App.vue, won't change afterwards
    },
    SET_AUTOSAVE_TIMER(state, value) {
      state.autosaveTimer = value;
    },
    SET_NSYLLABUS(state, nSyllabus) {
      state.nSyllabus = nSyllabus;
    },
    SET_SYLLABUSES(state, syllabuses) {
      state.syllabuses = syllabuses;
    },
    SET_OUTCOMES(state, outcomes) {
      state.outcomes = outcomes;
    },
    SET_PROGRESSIONS(state, progressions) {
      state.progressions = progressions;
    },
    SET_PROGRESSIONSV3(state, progressions) {
      state.progressionsV3 = progressions;
    },
    SET_RESOURCE_IMAGES(state, resurceImages) {
      state.resourceImages = resurceImages;
    },
    SET_TOTAL_RESOURCE_IMAGES(state, total) {
      state.total = total;
    },
    SET_PAGE(state, page) {
      state.page = page;
    },
    SET_PER_PAGE(state, perPage) {
      state.perPage = perPage;
    }
  },

  actions: {
    async fetchResourceImages({commit}) {
      const resouceImages = await api.metadata.getResourcesImages(metadataBaseUrl);

      commit('SET_RESOURCE_IMAGES', resouceImages)
      return resouceImages;
    },
    async fetchNsyllabus({commit}, payload) {
      const syllabusesObjects = await api.metadata.getSyllabuses(metadataBaseUrl, payload);
      const nsyllabus = [...new Set(syllabusesObjects.map(s => s.syllabus))];
      commit('SET_NSYLLABUS', nsyllabus)
      return nsyllabus
    },
    async fetchFiltersMetadata({commit}) {
      const filtersMetadata = await api.metadata.getFiltersMetadata(metadataBaseUrl);
      commit('SET_FILTERS_METADATA', filtersMetadata);
      commit('SET_AUTOSAVE_TIMER', filtersMetadata.autosavetimer.milliseconds);
      return filtersMetadata;
    },
    async fetchStatusesMetadata({commit}) {
      const statusesMetadata = await api.metadata.getStatusesMetadata(metadataBaseUrl);
      commit('SET_STATUSES_METADATA', statusesMetadata);
      return statusesMetadata;
    },
    async fetchSyllabuses({commit}, payload) {
      const syllabusesObjects = await api.metadata.getSyllabuses(metadataBaseUrl, payload);
      const syllabuses = [...new Set(syllabusesObjects.map(s => s.syllabus))];
      commit('SET_SYLLABUSES', syllabuses);
      return syllabuses;
    },
    async fetchOutcomes({commit}, payload) {
      const outcomes = await api.metadata.getOutcomes(metadataBaseUrl, payload);
      commit('SET_OUTCOMES', outcomes);
      return outcomes;
    },
    async fetchProgressions({commit}) {
      const progressions = [
        {
          name: 'National Literacy Learning Progression',
          id: 'literacy',
          children: []
        },
        {
          name: 'National Numeracy Learning Progression',
          id: 'numeracy',
          children: []
        }
      ]
      const progressionsData = await api.metadata.getProgressions(metadataBaseUrl);
      if (progressionsData) {
        progressions[0].children = progressionsData.filter(p => p.type === 'literacy');
        progressions[1].children = progressionsData.filter(p => p.type === 'numeracy');

        progressions.forEach((progression: any) => {
          progression.children = progression.children.map((p: any) => {
            const subelement = {
              name: p.subelement,
              id: p.subelement,
              description: p.description,
              children: p.level
            }
            subelement.children = subelement.children.map(level => {
              return {
                text: level,
                id: level,
                level: level,
                type: p.type.toLowerCase(),
                url: `${p.type.toLowerCase() === 'literacy' ? URLS.PROGRESIONS.LITERACY : URLS.PROGRESIONS.NUMERACY}${level}`,
                subElement: p.subelement,
                element: p.element
              }
            });
            return subelement;
          })
        })
      }

      const v2progressions = [
        {
          name: 'Version 2',
          id: 'version2',
          children: progressions
        }
      ]
      console.log('SET_PROGRESSIONS', v2progressions)
      commit('SET_PROGRESSIONS', v2progressions);
      return progressions;
    },
    async fetchProgressionsV3({commit}) {
      const progressions = [
        {
          name: 'National Literacy Learning Progression',
          id: 'literacy',
          children: []
        },
        {
          name: 'National Numeracy Learning Progression',
          id: 'numeracy',
          children: []
        }
      ]
      const progressionsData = await api.metadata.getProgressionsV3(metadataBaseUrl);
      if (progressionsData) {
        progressions[0].children = progressionsData.filter(p => p.area === 'Literacy');
        progressions[1].children = progressionsData.filter(p => p.area === 'Numeracy');

        progressions.forEach((progression: any) => {
          progression.children = progression.children.map((p: any) => {
            const subelement = {
              name: p.subelement,
              id: p.subelement,
              description: p.description,
              children: p.levels
            }
            subelement.children = subelement.children.map(v3level => {
              // currently indicators are not required so no need to save
              return {
                text: v3level.level,
                level: v3level.level,
                type: p.type.toLowerCase(),
                id: v3level.level,
                url: `${p.type.toLowerCase() === 'literacy' ? URLS.PROGRESIONS.LITERACY : URLS.PROGRESIONS.NUMERACY}${v3level.level}`,
                subElement: p.subelement,
                element: p.element
              }
            });
            return subelement;
          })
        })
      }

      const v3progressions = [
        {
          name: 'Version 3',
          id: 'version3',
          children: progressions
        }
      ]

      console.log('SET_PROGRESSIONSV3', v3progressions)
      commit('SET_PROGRESSIONSV3', v3progressions);
      return progressions;
    },
  }
};

export default moduleMetadata;
