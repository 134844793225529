<template>
  <Dialog
    v-bind="$attrs"
    icon="mdi-alert-circle-outline"
    title="Confirm edit and unpublish collection"
    :width="$vuetify.breakpoint.xsOnly ? 360 : 800"
    :fullscreen="!$vuetify.breakpoint.smAndUp"
    :darkHeading="$vuetify.breakpoint.smAndUp ? false : true"
    v-on="$listeners"
  >
    <template #content>
      <v-container class="new-collection-content">
        <div
          class="ma-3"
          :style="{'color': ADS_Colors.Dark}"
        >
          <p class="mb-6">
            Are you sure you want to remove these resources in this collection?
          </p>

          <p>
            Published collections cannot have less than two resources.
            Please click 'Continue' to unpublish the collection and remove the selected resource(s). Clicking 'Cancel' will keep the collection published, allowing you to add other resources to it.
          </p>
        </div>
        <v-divider v-if="!$vuetify.breakpoint.smAndUp" />
        <v-card-actions :class="{'d-flex justify-end px-6 py-6': true, 'footer-mobile': $vuetify.breakpoint.xsOnly}">
          <AdsButton
            secondary
            button-text="Cancel"
            @click="$downwardBroadcast('close:dialog')"
            @keyup.enter="$downwardBroadcast('close:dialog')"
          />
          <AdsButton
            :loading="loading"
            primary
            button-text="Continue"
            @click="confirmUnpublish"
            @keyup.enter="confirmUnpublish"
          />
        </v-card-actions>
      </v-container>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialogs/Dialog';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';

export default {
  name: 'ConfirmEditAndUnpublish',
  components: {
    Dialog,
    AdsButton
  },
  props: {
    collectionId: {
      type: String,
      default: ''
    },
    collectionName: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      ADS_Colors: ADS_Colors,
      loading: false
    }
  },
  created() {
    console.log(this.collectionName)
  },
  methods: {
    confirmUnpublish: async function () {
      this.$emit('confirm')
      this.$downwardBroadcast('close:dialog');
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../scss/mixins";

.new-collection-content{

  @include respond(phone){

    display: flex;
    flex-flow: column;
    position: relative;
    height: 100%;

    .data-content{
      min-height: 80vh;
    }
    .footer-mobile{
      width: 100%;
    }

  }
}
</style>
