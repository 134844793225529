<template>
  <div class="mt-3">
    <v-container
      v-if="$vuetify.breakpoint.mdAndUp"
      style="padding: 0;"
      fluid
    >
      <Banner
        id="main-content"
        heading="Community"
        subtitle="Join the conversation on Yammer or a Statewide staffroom"
        icon="storefront"
        :icon-size="bannerIconSize"
        tabindex="-1"
      />
    </v-container>

    <v-container
      fluid
      class="mt-3"
    >
      <v-row>
        <v-col
          cols="12"
          sm="10"
          class="pt-0"
        >
          <v-tabs
            v-model="communityTab"
            slider-color="#CE0037"
            color="#121212"
            background-color="transparent"
          >
            <v-tab
              id="tab-1"
              class="community--page--tab"
              @click="showDLRHTabContent"
            >
              URH Viva Engage
            </v-tab>
            <v-tab
              id="tab-2"
              class="community--page--tab"
              @click="showStaffRoomsContent"
            >
              STAFFROOMS
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-row
        v-show="isDLRHYammer"
        class="px-3"
      >
        <div
          id="yammer-embedded-feed-dlrh"
          :class="{'yammer-mobile': $vuetify.breakpoint.xs, 'yammer-desktop':$vuetify.breakpoint.smAndUp}"
        />
      </v-row>

      <v-row v-show="isStaffRoom">
        <v-col>
          <p>
            Click on the links below to be taken to the Microsoft Team for each of the Statewide Staffrooms that have
            been established. Follow the instructions within the team if you've not registered
            access before.
          </p>
        </v-col>
      </v-row>
      <v-row v-show="isStaffRoom">
        <v-col>
          <v-card>
            <v-list>
              <StaffRoomItem
                v-for="(item, index) in items"
                :key="index"
                :color="item.color"
                :title="item.title"
                :text="item.text"
                :url="item.url"
              />
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {Banner} from '@nswdoe/doe-ui-core';
import StaffRoomItem from './StaffRoomItem';

export default {
  name: 'CommunityPage',
  components: {
    Banner,
    StaffRoomItem
  },
  data() {
    return {
      communityTab: 0,
      isDLRHYammer: true,
      isDOEYammer: false,
      isStaffRoom: false,
      bannerIconSize: '3.5rem',
      items: [
        {
          color: '#DB3F29',
          title: 'AA',
          text: 'Aboriginal education',
          url: 'https://teams.microsoft.com/l/team/19%3ade69dd0df2aa4bd7bb2d6ebdbbf8863f%40thread.tacv2/conversations?groupId=f44b1107-14e1-4042-af2a-6024389a144f&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#71ABBD',
          title: 'AE',
          text: 'Anti-racism education statewide staffroom',
          url: 'https://teams.microsoft.com/dl/launcher/launcher.html?url=%2F_%23%2Fl%2Fteam%2F19%3AXXrGzsLQ6kopfUR9DHMbRo6E2m-uTywdG5xd2qFMcqo1%40thread.tacv2%2Fconversations%3FgroupId%3D541ead6f-5d9d-4e3e-afc1-a7a24fab843c%26tenantId%3D05a0e69a-418a-47c1-9c25-9387261bf991&type=team&deeplinkId=d4b89175-d64b-491f-acd0-ad6b0ba9124c&directDl=true&msLaunch=true&enableMobilePage=true&suppressPrompt=true'
        },
        {
          color: '#D14488',
          title: 'CT',
          text: 'Careers and transition advisers NSW External link',
          url: 'https://teams.microsoft.com/l/team/19%3a726e70a9f82c4325925f0724272c2c89%40thread.tacv2/conversations?groupId=797f30ef-8067-4d57-8060-74c95495426f&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#7954F4',
          title: 'CA',
          text: 'Creative Arts NSW',
          url: 'https://teams.microsoft.com/l/channel/19%3af8e495424602437ca4b119d55ecdc761%40thread.tacv2/General?groupId=08ce92f4-ef40-412b-8532-d2139f5ffbfa&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#71ABBD',
          title: 'DL',
          text: 'Disability, learning and support NSW External link',
          url: 'https://teams.microsoft.com/l/team/19%3ab147cbe2b560477a8cf1d967cd2d7691%40thread.tacv2/conversations?groupId=6460fb9c-2fab-493e-a476-67a9c628059f&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#757575',
          title: 'EA',
          text: 'EAL/D NSW',
          url: 'https://teams.microsoft.com/l/team/19%3ab2c31ab7e5a44495be88f6af7019975b%40thread.tacv2/conversations?groupId=3552b03d-8e93-42ac-ab55-75cf1961a7fa&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#DC4F86',
          title: 'EL',
          text: 'Early learning NSW',
          url: 'https://teams.microsoft.com/l/team/19%3a8ffd8e2859e44e55b2e1b288b8031096%40thread.skype/conversations?groupId=7df231e2-acc8-42fb-b711-d37c1cfb8f63&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#7954F4',
          title: 'EN',
          text: 'English NSW',
          url: 'https://teams.microsoft.com/l/team/19%3a88aaff1954984b3d821940244a27a355%40thread.skype/conversations?groupId=7cace238-04f1-4f87-a5dc-d823e51c9765&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#71ABBD',
          title: 'HP',
          text: 'High potential and gifted education statewide staffroom',
          url: 'https://teams.microsoft.com/l/team/19%3Abe424da0b4094665b186dc8bb0f24236%40thread.tacv2/conversations?groupId=8d23f40b-74dd-4563-a12c-7801d0d97694&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#71ABBD',
          title: 'HS',
          text: 'HSIE NSW',
          url: 'https://teams.microsoft.com/l/team/19%3ace47173b5fe14e16918eac8ca5e40913%40thread.skype/conversations?groupId=cc91cc45-b966-4333-b01f-31e78225fac4&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#7954F4',
          title: 'K6',
          text: 'K-6 NSW',
          url: 'https://forms.office.com/pages/responsepage.aspx?id=muagBYpBwUecJZOHJhv5kUwsTv61JFtLox4KgWmDAmxUNU45WDlDVEUxUEE3WVpEU0o5ME5KM0w0VyQlQCN0PWcu'
        },
        {
          color: '#757575',
          title: 'LN',
          text: 'Languages NSW',
          url: 'https://www.yammer.com/det.nsw.edu.au/#/threads/inGroup?type=in_group&feedId=7865831'
        },
        {
          color: '#C64700',
          title: 'LT',
          text: 'Literacy NSW',
          url: 'https://teams.microsoft.com/l/team/19%3a42316349d42e47178cba9da92ab992dd%40thread.tacv2/conversations?groupId=26306aea-e8d5-49fe-88fd-4da661f01fd5&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#D14488',
          title: 'MN',
          text: 'Mathematics NSW',
          url: 'https://teams.microsoft.com/l/team/19%3a97fe6296d321434f88ba0c5eb088f19a%40thread.skype/conversations?groupId=29a9a798-7ca9-43c3-9090-bf8ee5e0545a&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#7954F4',
          title: 'NN',
          text: 'Numeracy NSW',
          url: 'https://teams.microsoft.com/l/team/19%3a25f15c444946478698aa09a497223ab6%40thread.tacv2/conversations?groupId=1b4d6e82-09f3-4598-ac69-8a47327eedc6&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#757575',
          title: 'PN',
          text: 'PDHPE NSW',
          url: 'https://teams.microsoft.com/l/team/19%3a93bb42a54e4b4779b28ab5b737b9e642%40thread.tacv2/conversations?groupId=d759a943-a680-4d0b-bdfe-88a8998f709e&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#DC4F86',
          title: 'SA',
          text: 'School Administration and Support Staff',
          url: 'https://teams.microsoft.com/l/team/19%3a1860a340825042119a5271626851130e%40thread.tacv2/conversations?groupId=10916737-cb8e-4d42-910d-905775f49328&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#7954F4',
          title: 'SN',
          text: 'Science NSW',
          url: 'https://teams.microsoft.com/l/team/19%3a3e87f439f8624938be4c5ecb207850b9%40thread.tacv2/conversations?groupId=12f8b9b9-7db7-4eb0-b725-771d08250d68&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#71ABBD',
          title: 'SS',
          text: 'STEM secondary NSW',
          url: 'https://teams.microsoft.com/l/team/19%3a03fdaacc74f1489581e858e9a6716851%40thread.tacv2/conversations?groupId=8c9f3d61-e326-432f-8b46-d5655cec5e21&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#DB3F29',
          title: 'TN',
          text: 'TAS NSW',
          url: 'https://teams.microsoft.com/l/team/19%3acd41312b69a14cd38a7c429ffd90493a%40thread.tacv2/conversations?groupId=cd5a04e1-7742-47dd-b141-9519486d9e00&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#D14488',
          title: 'TL',
          text: 'Teacher librarians NSW',
          url: 'https://teams.microsoft.com/l/team/19%3af2ed882a30ee4ed0bead2e05ebea6ade%40thread.tacv2/conversations?groupId=1297bf09-3741-4795-b766-231acb90ee77&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#71ABBD',
          title: 'TL',
          text: 'Teaching and Learning 7-12 Statewide Staffroom',
          url: 'https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fforms.office.com%2Fpages%2Fresponsepage.aspx%3Fid%3DmuagBYpBwUecJZOHJhv5kcU60XDOX_9OgS05z3lXbQFUNlJZOTg0OFRLSTlKN1pOSzVGQTFRR09aSS4u&data=05%7C01%7Cgabrielle.smith37%40det.nsw.edu.au%7C69a68a37b7744e09c94b08da7c136e92%7C05a0e69a418a47c19c259387261bf991%7C0%7C0%7C637958720142496223%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=%2Fuon9M%2Bfn%2B%2BQbsK9y0apVuozxNPRaOwBYn6pCg4JHfY%3D&reserved=0'
        },
        {
          color: '#71ABBD',
          title: 'VS',
          text: 'VET Statewide Staffroom',
          url: 'https://aus01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fforms.office.com%2Fpages%2Fresponsepage.aspx%3Fid%3DmuagBYpBwUecJZOHJhv5kYzE7KGSnTpLuKR-lFwTHFNUNFI5TzJMSFJBVkRQUFpSREJKUlkxWldKUSQlQCN0PWcu&data=05%7C01%7Cgabrielle.smith37%40det.nsw.edu.au%7C69a68a37b7744e09c94b08da7c136e92%7C05a0e69a418a47c19c259387261bf991%7C0%7C0%7C637958720142340014%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=ZL9fkzaUbvlSGMCGtMXO%2F12DmX7HsqD6e3FM87I%2B6Io%3D&reserved=0'
        },
        {
          color: '#7954F4',
          title: 'WS',
          text: 'Wellbeing statewide staffroom',
          url: 'https://teams.microsoft.com/l/team/19%3abdefc1fe0ffa462291dc6fdbeff5b07d%40thread.tacv2/conversations?groupId=55c33a33-df35-4af3-b625-84795b7c0eb4&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
        {
          color: '#71ABBD',
          title: 'WE',
          text: 'Work Education 7-10 and Work Studies Stage 6',
          url: 'https://teams.microsoft.com/l/team/19%3a329325b5305d4403a9aa72870da549c4%40thread.skype/conversations?groupId=d4432396-444a-4c70-975d-e7e24f6e8e2e&tenantId=05a0e69a-418a-47c1-9c25-9387261bf991'
        },
      ]
    }
  },
  mounted() {
    console.log('Environment: ' + JSON.stringify(window.dlrhConfig.envUI));
    this.displayDLRHYammerFeed();
  },
  methods: {
    displayDLRHYammerFeed() {
      // issue is if the user refreshes the page directly from the Communitypage, or if they load the CommunityPage directly from URL
      // the script referenced from index.html doesn't get loaded, so we load it here and give it 2 seconds to completely load before loading DLRHYammer
      // or else we get the window.yam = undefined error
      let script = document.createElement('script');
      script.setAttribute('src', 'https://s0-azure.assets-yammer.com/assets/platform_embed.js');
      window.document.head.appendChild(script);


      setTimeout(() => {
        console.log('displayDLRHYammerFeed, window.yam = ', window.yam);
        if (window.yam === undefined) {
          console.error('CommunityPage - window.yam is undefined');
        } else {
          window.yam.connect.embedFeed({
            container: '#yammer-embedded-feed-dlrh',
            network: 'det.nsw.edu.au',
            feedType: 'group',
            feedId: '32560553984'
          });
        }
      }, 2000);
    },
    showDLRHTabContent() {
      if (this.communityTab === 1) {
        this.isDLRHYammer = true;
        this.isStaffRoom = false;
      }
    },
    showStaffRoomsContent() {
      if (this.communityTab === 0) {
        this.isDLRHYammer = false;
        this.isStaffRoom = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../scss/variables';

.v-container{
  height: 100vh;
  overflow: hidden;
}
.yammer-desktop {
  min-height: calc(100vh - 300px);
  min-width: 100%;
}

.yammer-mobile {
  min-height: 500px;
  min-width: 300px;
}

.v-tab:before {
  border: 4px solid $ads-red !important;
}

.community--page--tab:focus {
  border-bottom: 4px solid $ads-navy !important;
  outline: 3px solid transparent !important;
}

.community--page--tab:before {
  content: none;
}
</style>
