<template>
  <BasePage
    :banner="banner"
    :card="{maxSize: 424, minSize: $vuetify.breakpoint.mdAndDown ? 288 : 310}"
    :applyMaxWidth="showMaxWidth"
  >
    <div class="resource-editor-page">
      <v-row
        justify="space-between"
        align="center"
        class="resource-editor-page__header mb-2"
      >
        <v-col class="resource-editor-page__filters">
          <FiltersForEditor />
        </v-col>
        <v-col
          class="resource-editor-page__selected-resources"
          align="right"
        >
          <template v-if="canEditResources">
            {{ selectedResourcesMessage }}
            <template v-if="selectedResources.length > 0">
              <AdsButton
                class="pa-0"
                tertiary
                compact
                button-text="Clear"
                icon="cancel"
                aria-label="clear resource selection"
                @click="clearSelectedResources"
              />
            </template>
            <ActionsMenu
              offset-y
              left
              :button="{
                text: 'Actions',
                icon: 'mdi-dots-vertical',
                secondary: true
              }"
              :items="actions(selectedResources)"
              :disable="selectedResources.length === 0"
              @clickedAction="handleActionClick"
            />
            <AdsButton
              class="pa-5 ml-3"
              button-text="New Resource Card"
              depressed
              icon="mdi-plus-circle-outline"
              @click="$router.push('/resourceeditor/new')"
            />
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          To create a new collection of resources, please start by selecting from the resources below, then click on the 'Actions' button to proceed.
          Please note: this functionality is currently only available for URH resources and not for 'Equella replacement' users.
        </v-col>
      </v-row>
      <v-row
        justify="space-between"
        align="center"
      >
        <EditorTable
          v-model="selectedResources"
          @onPaginationChange="handlePaginationChange"
        />
      </v-row>
      <SubmittedDialog v-model="reviewSubmitted" />
      <DeclinedDialog v-model="declineSubmitted" />
      <EndorsedDialog v-model="endorseSubmitted" />
      <PublishedDialog v-model="publishSubmitted" />
      <UnpublishedDialog v-model="unpublishSubmitted" />
      <SaveToCollectionDialog
        v-model="showSaveToCollectionDialog"
        :selectedCards="selectedResources"
        :subTitle="saveToCollectionSubtitle"
        hubCollection
        :workflow="!isPublisher"
        @onReviewSubmitted="handleReviewSubmitted"
      />
      <UpdateStatusDialog
        v-model="showUpdateStatus"
        :successful-resources="successInUpdate"
        :failed-resources="failedInUpdate"
        :action="updateAction"
      />
    </div>
  </BasePage>
</template>

<script>
import BasePage from '@/components/BasePage.vue';
import {ADS_Colors, AdsButton} from '@nswdoe/doe-ui-core';
import {QUERIES, SORT_OPTIONS, DATATABLE_ITEMS_STATUS, RESOURCE_APPLICATION} from '@/constants';
import EditorTable from '@/views/ResourceEditorPage/EditorTable'
import FiltersForEditor from '@/views/ResourceEditorPage/FiltersForEditor';
import {mapGetters} from 'vuex';
import {pluralise} from '@/transforms';
import {delay} from '@/store/helpers';
import SubmittedDialog from '@/components/Dialogs/Editor/SubmittedDialog.vue';
import DeclinedDialog from '@/components/Dialogs/Editor/DeclinedDialog.vue';
import EndorsedDialog from '@/components/Dialogs/Editor/EndorsedDialog.vue';
import PublishedDialog from '@/components/Dialogs/Editor/PublishedDialog.vue';
import UnpublishedDialog from '@/components/Dialogs/Editor/UnpublishedDialog.vue';
import ActionsMenu from '@/views/MyResourceDetailsPage/ActionsMenu/ActionsMenu'
import SaveToCollectionDialog from '@/components/Dialogs/Collections/SaveToCollection'
import UpdateStatusDialog from '@/components/Dialogs/Editor/UpdateStatusDialog';

export default {
  name: 'ResourceEditorPage',
  components: {
    UpdateStatusDialog,
    UnpublishedDialog,
    PublishedDialog,
    EndorsedDialog,
    DeclinedDialog,
    SubmittedDialog,
    BasePage,
    FiltersForEditor,
    AdsButton,
    EditorTable,
    ActionsMenu,
    SaveToCollectionDialog
  },
  data() {
    return {
      banner: {
        heading: 'Resource Editor',
        subtitle: 'Create resource for our library',
        icon: 'vertical_split',
        iconSize: '3.5rem',
        showStatusCounts: true,
      },
      showMaxWidth: true,
      selectItems: [
        {
          text: 'All',
          value: ''
        },
        {
          text: 'School improvement',
          value: 'sir',
          icon: {
            name: 'build',
            background: ADS_Colors.Warning_Orange,
            color: 'white',
            size: '20'
          }
        },
        {
          text: 'Teaching and learning',
          value: 'tlr',
          icon: {
            name: 'school',
            background: ADS_Colors.Blue_1,
            color: 'white',
            size: '20'
          }
        }
      ],
      selectedResources: [],
      searchFieldError: false,
      queriesOptions: QUERIES,
      sortOptions: SORT_OPTIONS,
      validCharacters: v => !v || /^[a-zA-Z0-9 '.\-+=&_/"]*$/.test(v) || 'Search parameters cannot include the special characters used',
      showSaveToCollectionDialog: false,
      pagination: {},
      showUpdateStatus: false,
      successInUpdate: [],
      failedInUpdate: [],
      updateAction: ''
    }
  },
  computed: {
    ...mapGetters({
      userProfile: 'users/userProfile',
      isPublisher: 'users/isPublisher',
      isReviewer: 'users/isReviewer',
      isAuthor: 'users/isAuthor',
      isViewer: 'users/isViewer',
      hasPublisherAccessTo: 'users/hasPublisherAccessTo',
      scope: 'scope',
    }),
    canEditResources() {
      return this.isAuthor || this.isReviewer || this.isPublisher;
    },
    reviewSubmitted: {
      get() {
        return this.$store.state.editor.reviewSubmitted;
      },
      set(val) {
        this.$store.commit('editor/SET_REVIEW_SUBMITTED', val);
      }
    },
    declineSubmitted: {
      get() {
        return this.$store.state.editor.declineSubmitted;
      },
      set(val) {
        this.$store.commit('editor/SET_DECLINE_SUBMITTED', val);
      }
    },
    endorseSubmitted: {
      get() {
        return this.$store.state.editor.endorseSubmitted;
      },
      set(val) {
        this.$store.commit('editor/SET_ENDORSE_SUBMITTED', val);
      }
    },
    publishSubmitted: {
      get() {
        return this.$store.state.editor.publishSubmitted;
      },
      set(val) {
        this.$store.commit('editor/SET_PUBLISH_SUBMITTED', val);
      }
    },
    unpublishSubmitted: {
      get() {
        return this.$store.state.editor.unpublishSubmitted;
      },
      set(val) {
        this.$store.commit('editor/SET_UNPUBLISH_SUBMITTED', val);
      }
    },
    saveToCollectionSubtitle() {
      return `Choose only ONE collection to add the selected resources.`
    },
    selectedResourcesMessage() {
      return `${this.selectedResources.length} resource${pluralise(this.selectedResources.length)} selected`;
    }
  },
  watch: {
    showSaveToCollectionDialog(open) {
      if (!open) {
        this.$store.dispatch('editor/fetchResources', this.pagination);
      }
    },
    showUpdateStatus(val) {
      if (!val) {
        // reset values when the dialog is closed
        this.successInUpdate = []
        this.failedInUpdate = [],
        this.updateAction = ''
      }
    }
  },
  methods: {
    clearSelectedResources() {
      this.selectedResources = []
    },
    disableDeleteAction(selected) {
      // https://jira.education.nsw.gov.au/browse/DLRHUB-1899
      // Only draft resource cards can be deleted
      // An author can only delete collections they have created themselves AND that are in DRAFT or DECLINED Status only
      // A Viewer cannot delete any collections
      // A reviewer can only delete collections they have created themselves that are in DRAFT or DECLINED Status only
      // A publisher can delete any collection in the groups that are in the DRAFT or DECLINED  status only
      switch (true) {
        case !selected.every(({Status}) => Status === DATATABLE_ITEMS_STATUS.DRAFT || Status === DATATABLE_ITEMS_STATUS.DECLINED):
          return { disable: true, tooltip: "You can only delete 'draft' or 'declined' items" };
        case this.isPublisher && selected.every(({Status}) => Status === DATATABLE_ITEMS_STATUS.DRAFT):
          return { disable: false };
        case this.isPublisher && selected.every(({Status}) => Status === DATATABLE_ITEMS_STATUS.DECLINED):
          return { disable: false };
        case this.isAuthor:
        case this.isReviewer: {
          const disable = selected.some(({CreatedBy}) => CreatedBy !== this.userProfile.userId);
          return {
            disable,
            ...(disable && {tooltip: 'You may only delete your own collections'})
          };
        }
        default:
          return { disable: true };
      }
    },
    actions(item) {
      console.log('actions', item)
      // https://jira.education.nsw.gov.au/browse/DLRHUB-1900
      // ‘Add to collection’ button option should only be usable when ... ‘published’ status.
      // ‘Unpublish’ button option should only be usable when ... ‘published’ status.
      // The unpublish button option can only be used by “publisher” role.
      // ‘Submit for review’ button __ option should only be usable when ‘draft’
      const anyUnpublished = item.some(({Status}) => Status !== DATATABLE_ITEMS_STATUS.PUBLISHED);
      const allEndorsedOrDraft = item.every(({Status}) => Status === DATATABLE_ITEMS_STATUS.DRAFT || Status === DATATABLE_ITEMS_STATUS.ENDORSED);
      const anyCollectionsWithLessThan2Resources = item.some(({IsResourceCollection, NumSubResources}) => IsResourceCollection && NumSubResources < 2);
      const anyPublished = item.some(({Status}) => Status === DATATABLE_ITEMS_STATUS.PUBLISHED);
      const anyEquella = item.some(({Application}) => Application === RESOURCE_APPLICATION.EQUELLA);
      return [{
        id: 'addToCollection',
        name: 'Add to collection',
        tag: 'button',
        icon: 'mdi-bookmark-plus-outline',
        ...(anyUnpublished && {
          disable: true,
          tooltip: 'Only published can be added to collections'
        }),
        ...(anyEquella && {
          disable: true,
          tooltip: 'Only URH or OLP contents can be added to collections'
        })
      },
      // this.isPublisher && {
      //   id: 'unpublish',
      //   name: 'Unpublish selected',
      //   tag: 'button',
      //   icon: 'mdi-undo',
      //   ...(anyUnpublished && {
      //     disable: true,
      //     tooltip: 'You have unpublished resources selected'
      //   })
      // },
      this.isPublisher && {
        id: 'publish',
        name: 'Publish selected',
        tag: 'button',
        icon: 'mdi-publish',
        ...(!allEndorsedOrDraft && {
          disable: true,
          tooltip: 'You can only publish draft or endorsed resources'
        }),
        ...(anyCollectionsWithLessThan2Resources && {
          disable: true,
          tooltip: 'Cannot publish collections with less than 2 resources'
        })
      },
      {
        id: 'delete',
        name: 'Delete selected',
        tag: 'button',
        icon: 'mdi-delete-outline',
        ...this.disableDeleteAction(item)
      }].filter(Boolean);
    },
    async publishOrUnpublishedSelectedResources(isPublished) {
      console.log('selected resources', this.selectedResources)

      const blockedToPublishUnPublish = []

      const resourcesToPublish = this.selectedResources.filter(resource => {
        if (this.hasPublisherAccessTo.findIndex(ca => ca.id ===  resource.Source) > -1) {
          return resource
        } else {
          blockedToPublishUnPublish.push(resource)
        }
      })

      if (resourcesToPublish.length > 0) {
        const response = await this.$store.dispatch('editor/publishUnPublishResourcesWithData', {
          scope: this.scope,
          documents: resourcesToPublish,
          isPublished,
          failedResources: blockedToPublishUnPublish,
          displayMessage: blockedToPublishUnPublish.length === 0
        });
        if (response && blockedToPublishUnPublish.length > 0) {
          this.failedInUpdate = blockedToPublishUnPublish;
          this.successInUpdate = resourcesToPublish;
          this.showUpdateStatus = true;
          this.updateAction = isPublished ? 'unpublish' : 'publish';
        }
        await this.$store.dispatch('editor/fetchResources', this.pagination);
      } else {
        this.$store.commit('SET_ALERT', {
          type: 'error',
          text: 'There was an error updating the resources'
        }, { root: true });
        this.$store.commit('SET_DISPLAY_ALERT', true, {root: true});
        setTimeout(() => this.$store.commit('SET_DISPLAY_ALERT', false, {root: true}), 5000);
      }
      this.selectedResources = []
    },
    async deleteSelectedResources() {
      const payload = this.selectedResources.map(resource => ({
        resourceId: resource.ItemID,
        repository: resource.Source,
        application: resource.Application
      }));

      const response = await this.$store.dispatch('editor/deleteResources', payload);
      if (response) {
        this.selectedResources = [];
        await delay(1000);
        this.$store.dispatch('editor/fetchResources', this.pagination);
      }
    },
    handleActionClick(action) {
      switch (action) {
        case 'addToCollection':
          this.showSaveToCollectionDialog = !this.showSaveToCollectionDialog;
          break;
        case 'publish':
          this.publishOrUnpublishedSelectedResources(false);
          break;
        case 'unpublish':
          this.publishOrUnpublishedSelectedResources(true);
          break;
        case 'delete':
          this.deleteSelectedResources();
          break;
      }
    },
    handlePaginationChange(pagination) {
      this.pagination = pagination;
    },
    handleReviewSubmitted() {
      this.selectedResources = [];
      console.log('pagination', this.pagination)
      this.$store.dispatch('editor/fetchResources', this.pagination);
    }
  }
}
</script>

<style lang="scss" scoped>
.resource-editor-page {
  &__header {
    display: grid;
    grid-template-columns: repeat(auto-fit, 164px);
  }

  &__filters {
    grid-column: span 2;
  }

  &__selected-resources {
    grid-column: span 4;
    font-size: 14px;
  }
}
</style>
